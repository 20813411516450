// CKEditorComponent.jsx
import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CKEditorComponent = ({ setEditorData, initialValue }) => {
  const [editorData, setEditorContent] = useState(initialValue || "");
  useEffect(() => {
    setEditorContent(initialValue);
  }, [initialValue]);
  return (
    <div>
      <h2>CKEditor 5 in React</h2>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onReady={(editor) => {
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorContent(data); // Update local state
          setEditorData(data); // Update the parent state through prop
          console.log({ event, data });
        }}
        onBlur={(event, editor) => {
          console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          console.log("Focus.", editor);
        }}
      />
    </div>
  );
};

export default CKEditorComponent;
