import React, { useEffect } from "react";
import { Button, Col, Form, InputGroup, NavLink, Row } from "react-bootstrap";

import "./inbox.css";
import { MdSend } from "react-icons/md";
import { ImAttachment } from "react-icons/im";
import { FaUserCircle } from "react-icons/fa";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import getCookie from "../../../Component/GetCookie";
import { Skeleton } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { RabbitSVG } from "../../../Component/SVG/RabbitSVG";
import { BsDot, BsThreeDots } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { BiImage } from "react-icons/bi";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
import { TiGroup } from "react-icons/ti";
import { TbMenu } from "react-icons/tb";

const UserInbox = () => {
  const clientMsg = useRef();
  const userId = useRef();
  const groupId = useRef();
  const div = useRef();
  const groupChatVal = useRef();
  const chatBox = useRef();
  const groupChatBox = useRef();
  const [searchUserInfo, setSearchUserInfo] = useState([]);
  const [latestUserList, setLatestUserList] = useState([]);
  const [active, setActive] = useState(null);
  const [fetchUser, setFetchUser] = useState(null);
  const [searchVal, setSearchValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);

  const [groupChat, setGroupChat] = useState([])
  const [groupChatId, setGroupChatId] = useState()
  const [singleGroup, setSingleGroup] = useState(null)
  const [groupMessages, setGroupMessages] = useState([])

  const fetchGroupChat = () => {
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/group-chat/my-group`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setGroupChat(res.data?.my_groups);
      });
  };

  const fetchGroupMessages = () => {
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/group-chat/messages/${groupId.current.value}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setGroupMessages(res.data?.messages);
      });
  };

  const handleGroup = (id) => {
    setGroupChatId(id)

    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/group-chat/my-group/${id}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setSingleGroup(res.data?.single_group)
        groupChatBox.current.style.display = "block"
        groupChatBox.current.classList.add('open')
      });
  }

  // groupchat submit to backend
  const storeGroupChat = (e) => {
    const formdata = new FormData();
    formdata.append("message", groupChatVal.current.value);
    formdata.append("group_id", groupId.current.value);
    if (groupChatVal.current.value != "") {
      axios
        .post(
          `${BACKEND_BASE_URL}/api/v2/rianaire/group-chat/store`,
          formdata,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )

        .then((response) => {
          if (response.data?.status == 200) {
            setTimeout(() => {
              setGroupMessages(response.data?.messages);
            }, 800);
            e.target.reset();
          }
        });
    }

    e.preventDefault();
  };

  const handleKeyPress = (event) => {
    const formdata = new FormData();
    formdata.append("name", searchVal);
    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/chat/search/user`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (res.data?.status == 200) {
          if (res.data?.users.length == 0) {
            setFetchUser(null);
          }
          setSearchUserInfo(res.data?.users);
        }
      });
    event.preventDefault();
  };

  const handleClient = (id) => {
    setMessages([]);
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/chat/search/user/${id}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setFetchUser(res.data?.user);
        setMessages(res.data?.messages);
        setSearchUserInfo([]);
        setSearchValue("");
        div.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        chatBox.current.style.display = "block"
        chatBox.current.classList.add('open')
      });
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("message", clientMsg.current.value);
    formdata.append("to_user_id", userId.current.value);
    if (clientMsg.current.value != "") {
      axios
        .post(`${BACKEND_BASE_URL}/api/v2/rianaire/chat/store`, formdata, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        })

        .then((response) => {
          if (response.data?.status === 200) {
            setTimeout(() => {
              setMessages(response.data?.messages);
              // setOldMsgLength(response.data?.messages.length);
            }, 800);
            e.target.reset();
          }
        });
    }

    e.preventDefault();
  };

  const sidebarLatestUserlist = () => {
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/chat/user`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setLatestUserList(res.data?.chat_user);
      });
  };


  const sortedItems = [...latestUserList].sort((a, b) => {
    // Convert the updated_at strings to Date objects for comparison
    const dateA = new Date(a.msg_time);
    const dateB = new Date(b.msg_time);

    // Compare the Date objects to determine the order
    // Use -1 to sort in descending order; use 1 to sort in ascending order
    return dateB - dateA; // Sorting in descending order (newest first)
    // return dateA - dateB; // Sorting in ascending order (oldest first)
  });

  const allUserMessage = () => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/chat/all-messages/${userId.current.value}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setMessages(res.data?.messages);
      });
  };


  useEffect(() => {
    const userList = setInterval(() => {
      sidebarLatestUserlist();
      fetchGroupMessages()
      allUserMessage();
    }, 5000);

    return () => {
      clearInterval(userList); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.

    }
  }, []);

  function collapseChatbox() {
    if (chatBox.current.classList.contains('open')) {
      chatBox.current.classList.remove('open')
    } else {
      chatBox.current.classList.add('open')
    }
  }
  function closeChatBox() {
    chatBox.current.style.display = "none"
  }


  function collapseGroupChatbox() {
    if (groupChatBox.current.classList.contains('open')) {
      groupChatBox.current.classList.remove('open')
    } else {
      groupChatBox.current.classList.add('open')
    }
  }
  function closeGroupChatBox() {
    groupChatBox.current.style.display = "none"
  }

  useEffect(() => {
    fetchGroupChat()
  }, [])

  return (
    <div className="main__container">

      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <Row className="g-0 p-0 inbox">
          <Col lg={3} xxl={3} className="inbox_list ">
            <div className="chatbox_menu p-3">
              {/* <Row className=" flex_start mt-3 mb-4 pb-4">
                <Col xl={3} xxl={2}>
                  <div className="image_wrapper">
                    {getCookie("LOGGED_IN_ADMIN_PROFILE_PIC") != null ? (
                      <img
                        src={`${BACKEND_BASE_URL}${getCookie(
                          "LOGGED_IN_ADMIN_PROFILE_PIC"
                        )}`}
                        alt="123"
                        className="w-100 h-100"
                      />
                    ) : (
                      <RabbitSVG width={56} height={56} />
                    )}
                  </div>
                </Col>
                <Col xl={9} xxl={10}>
                  <div className="ps-2">
                    <h6 className="name mb-1 text-capitalize">
                      {getCookie("LOGGED_IN_ADMIN_NAME")}
                    </h6>
                    <p className="chat_desc mb-0">
                      {" "}
                      {getCookie("LOGGED_IN_ADMIN_EMAIL")}
                    </p>
                  </div>
                </Col>
              </Row> */}
              <h4 className=" fw-bold mb-4 ps-2">Chats </h4>
              {/* Search Box  || Left column*/}
              <div className="px-2">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">🔎</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="search here..."
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyUp={handleKeyPress}
                    value={searchVal}
                  />
                </InputGroup>

                {searchUserInfo?.length > 0 && (
                  <div className="search_result_box">
                    {searchUserInfo?.map((user, index) => {
                      return (
                        <div key={index} className="">
                          <div className="search_result px-1 py-2">
                            <div
                              className="d-flex align-items-center cursor"
                              onClick={() => handleClient(user.id)}
                            >
                              <div className="image_wrapper ">
                                {user?.profile_photo ? (
                                  <img
                                    src={`${BACKEND_BASE_URL}/${user?.profile_photo}`}
                                    alt=""
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={require("../../../Assets/avatar.png")}
                                    alt=""
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                              <div className="ms-3">{user.name}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {/* Chat userlist || Left column */}
              {sortedItems?.map((userlist, index) => (
                <div
                  key={index}
                  onClick={() => {
                    handleClient(userlist.user_id);
                    setActive(userlist.user_id);
                  }}
                  className={`d-flex justify-content-between cursor user_list ${active == userlist.user_id && "active"
                    }  py-3 mx-2 px-2 mb-1`}
                >
                  <div className="flex_start">
                    <div className="image_wrapper">
                      {userlist?.profile_photo ? (
                        <img
                          src={`${BACKEND_BASE_URL}/${userlist?.profile_photo}`}
                          alt=""
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src={require("../../../Assets/avatar.png")}
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="ms-2">
                      <h6 className="name mb-1">{userlist?.user_name}</h6>
                      <p className="chat_desc mb-0">
                        {userlist?.last_message?.message.length > 35 ? userlist?.last_message?.message.slice(0, 35) + "..." : userlist?.last_message?.message}

                      </p>
                    </div>

                  </div>

                  <div className="">
                    <span className="time">
                      {moment(userlist?.msg_time).format("h:mm A")}
                    </span>
                  </div>


                </div>
              ))}
              <div className="ps-2"> <h4 className="fw-bold my-4">Groups</h4> </div>
              <div className="d-flex flex-column t-p-15 mb-2">
                {groupChat?.map((groupMember, i) => (
                  <>
                    <div className="flex_between mb-2">
                      <div className="image_wrapper me-2" style={{ width: "12%" }}>
                        <TiGroup size={"1.5rem"} />

                      </div>

                      <div onClick={() => {
                        handleGroup(groupMember.id);
                        setActive(groupMember.user_id);
                      }}
                        className="ps-2 cursor" style={{ width: "88%" }}>
                        <h6 className="name mb-1 text-capitalize">
                          {groupMember.group_name}
                          {/* {getCookie("LOGGED_IN_ADMIN_NAME")} */}
                        </h6>
                      </div>
                    </div>
                  </>
                ))}

              </div>
            </div>
          </Col>

          {/* Chat Body || Middle column */}
          <Col lg={3} xxl={3} className="group_chatbox_column">
            {fetchUser ?
              <div className="ps-3">
                <div ref={chatBox} className={`chatBox ${open ? "open" : ""}`} onClick={() => setOpen(true)}>

                  <div className="chat_header flex_between px-2 py-2">
                    <div className="d-flex justify-content-start align-items-center">

                      <div className="image_wrapper ">
                        {fetchUser?.profile_photo ? (
                          <img
                            src={`${BACKEND_BASE_URL}/${fetchUser?.profile_photo}`}
                            alt=""
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={require("../../../Assets/avatar.png")}
                            alt=""
                            className="img-fluid"
                          />
                        )}
                      </div>
                      <p className="mb-0 ps-3 name">

                        {fetchUser?.name ? fetchUser?.name : ""}

                      </p>
                    </div>
                    <div className="d-flex">
                      <TbMenu className={`me-2 cursor`} size="1.2rem" color="#6E6E6E" onClick={collapseChatbox} />
                      <IoClose className="me-1 cursor" size="1.2rem" color="#6E6E6E" onClick={closeChatBox} />
                    </div>
                  </div>
                  <div className="chat_body">
                    {messages.map((message, index) => (
                      <div key={index} className={`d-flex justify-content-start align-items-start px-2 pt-2`}>
                        <div className="me-2" style={{ width: "40px", borderRadius: "50%", overflow: "hidden" }}>
                          <div className="image_wrapper">
                            {message?.from_user_info?.profile_photo ? (
                              <img
                                src={`${BACKEND_BASE_URL}/${message?.from_user_info?.profile_photo}`}
                                alt=""
                                className="img-fluid me-2"

                              />
                            ) : (
                              <img
                                src={require("../../../Assets/avatar.png")}
                                alt=""
                                className="img-fluid me-2"

                              />
                            )}
                          </div>

                        </div>

                        <div>
                          <p className="chat_person mb-1">
                            {(message?.from_user_id == getLocalStorageWithExpiry("adminUserInfo")?.id) ?
                              message?.from_user_info?.name : message?.from_user_info?.name
                            }

                            <span className="time"><BsDot /> {moment(message?.created_at).format(
                              "h:mm A"
                            )}</span></p>
                          <p className="chat">{message?.message}</p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Form onSubmit={storeData}>
                    <div className="chat_footer_top">
                      <Form.Control
                        type="text"
                        placeholder="Write something"
                        ref={userId}
                        value={fetchUser?.id}
                        readOnly
                        hidden
                      />
                      <Form.Control
                        type="text"
                        placeholder="Your message..."
                        className="chat_input"
                        ref={clientMsg}
                      />
                      {/* <Form.Control className="chat_input" placeholder="Your message..." ref={groupChatVal} /> */}
                    </div>
                    <div className="chat_footer_bottom">
                      <div className="flex_between send_btn_box">
                        <div className="attachment">
                          <BiImage className="me-3" size="1.2rem" />
                          <ImAttachment size="1rem" />
                        </div>
                        <button type="submit" className="text_send_btn">Send</button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              :
              <div className="chat_details pt-5 px-4">
                <Row className="gx-0 mb-4">
                  <Col className="skeleton">
                    <Skeleton
                      animation="pulse"
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                    />
                    <Skeleton animation="pulse" height={30} width="60%" />
                    <Skeleton animation="pulse" width="90%" height="30px" />
                    <Skeleton animation="pulse" width="20%" height="30px" />
                  </Col>
                </Row>
                <Row className="gx-0 mb-4">
                  <Col className="skeleton">
                    <Skeleton
                      animation="pulse"
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2 ms-auto"
                    />
                    <Skeleton
                      animation="pulse"
                      height={30}
                      width="60%"
                      className="ms-auto"
                    />
                    <Skeleton
                      animation="pulse"
                      width="90%"
                      height="30px"
                      className="ms-auto"
                    />
                    <Skeleton
                      animation="pulse"
                      width="20%"
                      height="30px"
                      className="ms-auto"
                    />
                  </Col>
                </Row>
                <Row className="gx-0 mb-4">
                  <Col className="skeleton">
                    <Skeleton
                      animation="pulse"
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                    />
                    <Skeleton animation="pulse" height={30} width="60%" />
                    <Skeleton animation="pulse" width="90%" height="30px" />
                    <Skeleton animation="pulse" width="20%" height="30px" />
                  </Col>
                </Row>
                <Row className="gx-0 mb-4">
                  <Col className="skeleton">
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2 ms-auto"
                    />
                    <Skeleton
                      animation="wave"
                      height={30}
                      width="60%"
                      className="ms-auto"
                    />
                    <Skeleton
                      animation="wave"
                      width="90%"
                      height="30px"
                      className="ms-auto"
                    />
                    <Skeleton
                      animation="wave"
                      width="20%"
                      height="30px"
                      className="ms-auto"
                    />
                  </Col>
                </Row>
              </div>
            }

          </Col>

          {/* Group Chat Body || Middle column */}
          <Col lg={3} xxl={3} className="chatbox_column" >
            <div className="pe-2">
              {singleGroup != null && <>
                <div ref={groupChatBox} className={`chatBox ${open ? "open" : ""}`} onClick={() => setOpen(true)}>

                  <div className="chat_header flex_between px-2 py-2">
                    <div className="d-flex justify-content-start align-items-center">
                      <img
                        src={require("../../../Assets/avatar.png")}
                        alt=""
                        className="img-fluid img-circle"
                        width={35}
                      />
                      <p className="mb-0 ps-3 name">{singleGroup?.group_name}</p>
                    </div>
                    <div>
                      <TbMenu className={`me-2 cursor`} size="1.2rem" color="#6E6E6E" onClick={collapseGroupChatbox} />
                      <IoClose className="me-1 cursor" size="1.2rem" color="#6E6E6E" onClick={closeGroupChatBox} />
                    </div>
                  </div>
                  <div className="chat_body">
                    {groupMessages?.map((message) => (
                      <div className="d-flex justify-content-start align-items-start px-2 pt-2">
                        <img
                          src={require("../../../Assets/avatar.png")}
                          alt=""
                          className="img-fluid img-circle me-2"
                          width={40}
                        />
                        <div>
                          <p className="chat_person mb-1">{message?.user_info?.name} <span className="time"><BsDot /> {moment(message?.created_at).format(
                            "h:mm A"
                          )} </span></p>
                          <p className="chat">{message?.message}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Form onSubmit={storeGroupChat}>
                    <div className="chat_footer_top">
                      <Form.Control
                        type="text"
                        placeholder="Write something"
                        ref={groupId}
                        value={groupChatId}
                        readOnly
                        hidden
                      />
                      <Form.Control className="chat_input" placeholder="Your message..." ref={groupChatVal} />
                    </div>
                    <div className="chat_footer_bottom">
                      <div className="flex_between send_btn_box">
                        <div className="attachment">
                          <BiImage className="me-3" size="1.2rem" />
                          <ImAttachment size="1rem" />
                        </div>
                        <button type="submit" className="text_send_btn">Send</button>
                      </div>
                    </div>
                  </Form>
                </div>
              </>}
            </div>


            {/* {fetchUser ? (
              <div className="chatbox_body" ref={div}>
                <div className="chat_person px-3 py-4">
                  <div className="flex_start ">
                    <div className="chat_profile_pic">
                      {fetchUser?.profile_photo ? (
                        <img
                          src={`${BACKEND_BASE_URL}/${fetchUser?.profile_photo}`}
                          alt=""
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src={require("../../../Assets/avatar.png")}
                          alt=""
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="chat_person_name">
                      {fetchUser?.name ? fetchUser?.name : ""}
                    </div>
                  </div>
                </div>
                <div className="message_body">
                  <div className="chat_details py-4">

                    {messages.map((message, index) => (
                      <div className={`d-flex ${message.from_user_id == "0"
                        ? "justify-content-end"
                        : ""
                        }`} key={index} >
                        <div
                          className={`px-1 mb-3 px-xxl-2 d-flex align-items-end ${message.from_user_id == "0"
                            ? "order-1"
                            : "order-0 user_msg "
                            }`}
                        >
                          {message?.from_user_id != "0" ? (
                            fetchUser?.profile_photo ? (
                              <div className="image_wrapper">
                                <img
                                  src={`${BACKEND_BASE_URL}/${fetchUser?.profile_photo}`}
                                  alt=""
                                  className="rounded-circle"
                                  width="40"
                                  height="40"
                                  style={{ objectFit: "cover" }}
                                />
                              </div>

                            ) : (
                              <img
                                src={require("../../../Assets/avatar.png")}
                                alt=""
                                className="img-fluid img-circle"

                              />
                            )
                          ) : getCookie("LOGGED_IN_ADMIN_PROFILE_PIC") != null ? (
                            <div className="image_wrapper">
                              <img
                                src={`${BACKEND_BASE_URL}${getCookie(
                                  "LOGGED_IN_ADMIN_PROFILE_PIC"
                                )}`}
                                alt="123"
                                className="w-100 h-100"
                              />
                            </div>
                          ) : (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                width="40"
                                height="40"
                                x="0"
                                y="0"
                                viewBox="0 0 512 512"
                              >
                                <g>
                                  <g>
                                    <path
                                      d="m369.321 485.595c84.536-41.804 142.679-128.903 142.679-229.595 0-141.385-114.615-256-256-256s-256 114.615-256 256c0 100.692 58.143 187.791 142.679 229.595z"
                                      fill="#6595f5"
                                      data-original="#1dd882"
                                    ></path>
                                    <path
                                      d="m504.375 193.804-137.096-137.227-2.212-2.082-37.421 146.251-144.56-144.692-1.692-1.559-30.873 284.573 12.273 12.273 23.831-7.353 113.85 13.683 68.844 127.91c84.536-41.8 142.681-128.89 142.681-229.581 0-21.453-2.654-42.283-7.625-62.196z"
                                      fill="#5d6ed3"
                                      data-original="#0caa5f"
                                    ></path>
                                    <g>
                                      <path
                                        d="m204.579 137.021c0 59.732-18.095 127.002-40.416 127.002s-40.416-67.27-40.416-127.002 18.095-89.308 40.416-89.308 40.416 29.576 40.416 89.308z"
                                        fill="#e9edf5"
                                        data-original="#e9edf5"
                                      ></path>
                                    </g>
                                    <g>
                                      <path
                                        d="m186.616 145.687c-.351 33.02-10.748 70.1-23.087 69.969s-21.947-37.424-21.596-70.444 10.527-49.263 22.866-49.132c12.338.132 22.167 16.587 21.817 49.607z"
                                        fill="#cdd2e1"
                                        data-original="#cdd2e1"
                                      ></path>
                                    </g>
                                    <g>
                                      <path
                                        d="m307.421 137.021c0 59.732 18.095 127.002 40.416 127.002s40.416-67.27 40.416-127.002-18.095-89.308-40.416-89.308c-22.321.001-40.416 29.576-40.416 89.308z"
                                        fill="#cdd2e1"
                                        data-original="#cdd2e1"
                                      ></path>
                                    </g>
                                    <g>
                                      <path
                                        d="m325.384 145.687c.351 33.02 10.748 70.1 23.087 69.969s21.947-37.424 21.596-70.444-10.527-49.263-22.866-49.132c-12.338.132-22.167 16.587-21.817 49.607z"
                                        fill="#afb4c8"
                                        data-original="#afb4c8"
                                      ></path>
                                    </g>
                                    <path
                                      d="m184.299 463.544c0-39.599 32.102-71.701 71.701-71.701s71.701 32.102 71.701 71.701v38.26c14.491-4.22 28.458-9.67 41.763-16.259l-23.403-156.192h-180.122l-23.403 156.192c13.305 6.589 27.272 12.039 41.763 16.259z"
                                      fill="#e9edf5"
                                      data-original="#e9edf5"
                                    ></path>
                                    <path
                                      d="m256 391.843c39.599 0 71.701 32.102 71.701 71.701v38.26c14.491-4.22 28.458-9.67 41.763-16.259l-23.403-156.192h-90.075v62.491c.005 0 .009-.001.014-.001z"
                                      fill="#cdd2e1"
                                      data-original="#cdd2e1"
                                    ></path>
                                    <g>
                                      <path
                                        d="m381.541 287.176c0 61.042-56.207 83.508-125.541 83.508s-125.541-22.466-125.541-83.508 56.207-131.402 125.541-131.402 125.541 70.361 125.541 131.402z"
                                        fill="#ffffff"
                                        data-original="#ffffff"
                                      ></path>
                                    </g>
                                    <g>
                                      <path
                                        d="m256 155.774c-.005 0-.009 0-.014 0v214.91h.014c69.334 0 125.541-22.467 125.541-83.508s-56.207-131.402-125.541-131.402z"
                                        fill="#e9edf5"
                                        data-original="#e9edf5"
                                      ></path>
                                    </g>
                                    <g>
                                      <path
                                        d="m277.922 292.057c-8.588 0-35.256 0-43.844 0-16.95 0-30.69 13.741-30.69 30.69s13.74 30.69 30.69 30.69c8.588 0 16.337-3.514 21.908-9.199 5.57 5.685 13.348 9.199 21.936 9.199 16.95 0 30.69-13.741 30.69-30.69 0-16.95-13.74-30.69-30.69-30.69z"
                                        fill="#e9edf5"
                                        data-original="#e9edf5"
                                      ></path>
                                    </g>
                                    <g>
                                      <path
                                        d="m277.922 292.057c-4.296 0-13.117 0-21.936 0v52.182c.005-.005-.005.005 0 0 5.57 5.685 13.348 9.198 21.936 9.198 16.95 0 30.69-13.74 30.69-30.69s-13.74-30.69-30.69-30.69z"
                                        fill="#cdd2e1"
                                        data-original="#cdd2e1"
                                      ></path>
                                    </g>
                                    <g>
                                      <path
                                        d="m282.379 299.183c0 11.753-11.81 21.281-26.379 21.281s-26.379-9.528-26.379-21.281 11.81-12.702 26.379-12.702 26.379.949 26.379 12.702z"
                                        fill="#414952"
                                        data-original="#414952"
                                      ></path>
                                    </g>
                                    <g>
                                      <path
                                        d="m256 286.481c-.005 0-.009 0-.014 0v33.982h.014c14.569 0 26.379-9.528 26.379-21.281s-11.81-12.701-26.379-12.701z"
                                        fill="#23272b"
                                        data-original="#23272b"
                                      ></path>
                                    </g>
                                    <g>
                                      <g>
                                        <ellipse
                                          cx="181.394"
                                          cy="256.658"
                                          fill="#555a66"
                                          rx="17.267"
                                          ry="17.267"
                                          transform="matrix(.159 -.987 .987 .159 -100.886 394.842)"
                                          data-original="#555a66"
                                        ></ellipse>
                                      </g>
                                      <g>
                                        <ellipse
                                          cx="330.606"
                                          cy="256.658"
                                          fill="#333940"
                                          rx="17.267"
                                          ry="17.267"
                                          transform="matrix(.159 -.987 .987 .159 24.553 542.149)"
                                          data-original="#333940"
                                        ></ellipse>
                                      </g>
                                    </g>
                                    <g>
                                      <path
                                        d="m256 390.843c-39.599 0-71.701 32.102-71.701 71.701v39.279c22.751 6.625 46.811 10.177 71.701 10.177s48.95-3.552 71.701-10.177v-39.279c0-39.599-32.102-71.701-71.701-71.701z"
                                        fill="#ffffff"
                                        data-original="#ffffff"
                                      ></path>
                                    </g>
                                    <g>
                                      <path
                                        d="m256 390.843c-.005 0-.009 0-.014 0v121.157h.014c24.89 0 48.95-3.552 71.701-10.177v-39.279c0-39.599-32.102-71.701-71.701-71.701z"
                                        fill="#e9edf5"
                                        data-original="#e9edf5"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          )}
                        </div>
                        <div
                          className={` px-1 mt-1 mb-2 p-xxl-0 position-relative  ${message.from_user_id == "0"
                            ? "text-end admin_msg"
                            : "user_msg"
                            }`}
                        >
                          <p className="chat_desc mb-1">{message?.message}</p>

                          <small>
                            {moment(message?.created_at).format("DD MMM, Y")}
                          </small>
                        </div>
                      </div>
                    ))}

                  </div>
                </div>

                <div className="chat_bg">
                  <Form
                    className="send_chat_form position-relative"
                    onSubmit={storeData}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Write something"
                      ref={userId}
                      value={fetchUser?.id}
                      readOnly
                      hidden
                    />
                    <Form.Control
                      type="text"
                      placeholder="Write something"
                      ref={clientMsg}
                    />

                    <ImAttachment
                      size="1.2em"
                      color="#686868"
                      className="attachment cursor"
                    />

                    <Button
                      type="submit"
                      className="submit_btn"
                    // disabled={clientMsg.current.value == "" && "disabled"}
                    >
                      <MdSend size="1.5em" className="ms-1" />
                    </Button>
                  </Form>
                </div>
              </div>
            ) : (
              <div className="chat_details pt-5 px-4">
                <Row className="gx-0 mb-4">
                  <Col className="skeleton">
                    <Skeleton
                      animation="pulse"
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                    />
                    <Skeleton animation="pulse" height={30} width="60%" />
                    <Skeleton animation="pulse" width="90%" height="30px" />
                    <Skeleton animation="pulse" width="20%" height="30px" />
                  </Col>
                </Row>
                <Row className="gx-0 mb-4">
                  <Col className="skeleton">
                    <Skeleton
                      animation="pulse"
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2 ms-auto"
                    />
                    <Skeleton
                      animation="pulse"
                      height={30}
                      width="60%"
                      className="ms-auto"
                    />
                    <Skeleton
                      animation="pulse"
                      width="90%"
                      height="30px"
                      className="ms-auto"
                    />
                    <Skeleton
                      animation="pulse"
                      width="20%"
                      height="30px"
                      className="ms-auto"
                    />
                  </Col>
                </Row>
                <Row className="gx-0 mb-4">
                  <Col className="skeleton">
                    <Skeleton
                      animation="pulse"
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2"
                    />
                    <Skeleton animation="pulse" height={30} width="60%" />
                    <Skeleton animation="pulse" width="90%" height="30px" />
                    <Skeleton animation="pulse" width="20%" height="30px" />
                  </Col>
                </Row>
                <Row className="gx-0 mb-4">
                  <Col className="skeleton">
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={50}
                      height={50}
                      className="mb-2 ms-auto"
                    />
                    <Skeleton
                      animation="wave"
                      height={30}
                      width="60%"
                      className="ms-auto"
                    />
                    <Skeleton
                      animation="wave"
                      width="90%"
                      height="30px"
                      className="ms-auto"
                    />
                    <Skeleton
                      animation=""
                      width="20%"
                      height="30px"
                      className="ms-auto"
                    />
                  </Col>
                </Row>
              </div>
            )} */}

          </Col>


          <Col lg={3} xxl={3}>
            <div className="chat_person_info p-3">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
              facere expedita sapiente delectus qui, vel tempora. Provident
              minus aut et illum adipisci rerum laborum dicta similique, dolore
              excepturi quam error dolorum recusandae deleniti delectus
              voluptatem sapiente nulla consectetur reprehenderit repellendus.
              In, consequuntur impedit? Quisquam autem rem repudiandae
              cupiditate dolor atque.

            </div>
          </Col>
        </Row>
      </div>
    </div >
  );
};

export default UserInbox;
