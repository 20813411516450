import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import Parse from "html-react-parser";
import Swal from "sweetalert2";
import { BACKEND_BASE_URL } from "../../Component/GlobalVariables";
import AdminDashboardNavbar from "../../Component/AdminDashboardNavbar";
import RichTextEditor from "../../Component/RichTextEditor";
import {
  AiFillCloseSquare,
  AiOutlineClose,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { FaCheck, FaEye } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever, MdSystemUpdateAlt } from "react-icons/md";
import { CgClose } from "react-icons/cg";
import getCookie from "../../Component/GetCookie";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getLocalStorageWithExpiry } from "../../Component/LocalStorageWithExpiry";

const AdminOurProjectWorks = () => {
  const workTitle = useRef();
  const workFeaturedImage = useRef();
  const workMultipleImage = useRef();
  const shortDesc = useRef();
  const workCheckList = useRef();
  const workProjectCategoryDropdown = useRef();
  const workSubProjectCategoryDropdown = useRef();
  const workProjectClient = useRef();
  const workType = useRef();
  const workTools = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isChecked, setIsChecked] = useState(0);
  // const [imageLayout, setImageLayout] = useState("");

  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  const [validated, setValidated] = useState(false);

  const handleWorkChecklist = (e) => {
    if (e.target.checked) {
      setIsChecked(1);
    } else {
      setIsChecked(0);
    }
  };

  // const handleImageLayout = (e) => {
  //   if (e.target.checked) {
  //     setImageLayout(e.target.value);
  //   } else {
  //     setImageLayout("");
  //   }
  // };

  const [selectedSubCategory, setSelectedSubCategory] = useState([]);

  const handleChipChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSubCategory(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // Fetch Table Data
  const [tableData, setTableData] = useState([]);
  const [mainProjectCategory, setMainProjectCategory] = useState([]);
  const [subProjectCategory, setSubProjectCategory] = useState([]);
  const [sub, setSub] = useState(false);
  const [singleProjectId, setSingleProjectId] = useState();

  // Modal Section Data
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalSize, setModalSize] = useState("lg");

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${BACKEND_BASE_URL}/api/v3/rianaire/our-works-projects`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })

      .then((res) => {
        setIsLoading(false);
        setError(false);
        setTableData(res.data?.all_projects);
        setMainProjectCategory(res.data?.main_category);
        console.log(res.data);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getSubCategory = (e) => {
    e.preventDefault();
    setSubProjectCategory([]);
    setSub(false);

    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/our-work-categories/get-sub-category/${workProjectCategoryDropdown.current.value}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((res) => {
        setSubProjectCategory(res.data?.sub_category);

        if (res.data?.sub_category.length > 0) {
          setSub(true);
        }
      });
  };

  // Add new
  const addNewData = (modalValue) => {
    setFile([]);
    setSelectedImages([]);
    setModalSize("lg");
    setModalData(modalValue);
    setModalShow(true);
  };

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    const imagesArray = selectedFilesArray.map((file) => {
      return file;
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const deleteGalleryImage = (id) => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/our-works-projects/update/gallery-delete/${id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then(() => {
        axios
          .get(
            `${BACKEND_BASE_URL}/api/v2/rianaire/our-works-projects/show/${singleProjectId}`,
            {
              headers: {
                Authorization: `Bearer ${getLocalStorageWithExpiry(
                  "ACCESS_TOKEN"
                )}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status === 200) {
              setSingleData(response.data?.single_info);

              if (response.data?.single_info?.is_popular == 1) {
                setIsChecked(1);
              } else {
                setIsChecked(0);
              }
            }
          });
      });
  };

  // Form submit to backend
  const store = (e) => {
    e.preventDefault();
    // let category_id;
    // if (sub == true) {
    // category_id = workSubProjectCategoryDropdown.current.value;
    // } else {
    //   if (workProjectCategoryDropdown.current.value != "") {
    //     category_id = workProjectCategoryDropdown.current.value;
    //   }
    // }
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    const formdata = new FormData();
    formdata.append("title", workTitle.current.value);
    formdata.append("featured_image", workFeaturedImage.current.files[0]);
    formdata.append("description", shortDesc.current.value);
    formdata.append("client", workProjectClient.current.value);
    formdata.append("work_type", workType.current.value);
    formdata.append("tools", workTools.current.value);
    formdata.append("is_popular", isChecked);
    // formdata.append("layout_class", imageLayout);
    formdata.append(
      "parent_category_id",
      workProjectCategoryDropdown.current.value
    );
    // formdata.append("category_id", category_id);

    selectedSubCategory.forEach((item) => {
      formdata.append("category_ids[]", item);
    });
    selectedImages.forEach((item) => {
      formdata.append("image[]", item);
    });

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v3/rianaire/our-works-projects/store`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
          setValue("", "html");
          setSelectedImages([]);
          fetchData();
          setValidated(false);
          setModalShow(false);
          setSub(false);
        }
      });
  };

  // View Single Data
  const [singleData, setSingleData] = useState([]);
  console.log("singleData", singleData);
  const showSingleData = (modalValue, data) => {
    setModalSize("lg");
    setModalData(modalValue);
    setSingleData(data);
    setModalShow(true);
  };

  // Edit data
  const fetchDataForEdit = (modalValue, data) => {
    setSingleProjectId(modalValue?.id);
    setFile([]);
    setSelectedImages([]);

    setSingleData(data);

    let selectedSubcategory = data.project_our_work_categories.map((item) => {
      return item.id;
    });
    setSelectedSubCategory(selectedSubcategory);
    setModalData(modalValue);
    setModalSize("lg");
    setModalShow(true);
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/rianaire/our-work-categories/get-sub-category/${data.parent_category_id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSubProjectCategory(res.data?.sub_category);
      });
  };

  // Updated data to backend
  const updateData = (e) => {
    // if (sub == true) {
    // category_id = workSubProjectCategoryDropdown.current.value;
    // } else {
    //   if (workProjectCategoryDropdown.current.value != "") {
    //     category_id = workProjectCategoryDropdown.current.value;
    //   }
    // }
    const formdata = new FormData();
    formdata.append("_method", "PUT");
    formdata.append("title", workTitle.current.value);
    formdata.append("description", shortDesc.current.value);
    formdata.append("client", workProjectClient.current.value);
    formdata.append("work_type", workType.current.value);
    formdata.append("tools", workTools.current.value);
    // formdata.append("layout_class", imageLayout);
    formdata.append("is_popular", workCheckList.current.value);
    formdata.append(
      "parent_category_id",
      workProjectCategoryDropdown.current.value
    );
    // formdata.append("category_id", category_id);
    selectedSubCategory.forEach((item) => {
      formdata.append("category_ids[]", item);
    });

    if (workFeaturedImage.current.files[0]) {
      formdata.append("featured_image", workFeaturedImage.current.files[0]);
    }

    selectedImages.forEach((item) => {
      formdata.append("image[]", item);
    });

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v3/rianaire/our-works-projects/update/${singleData.id}`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        Swal.fire({
          icon: "success",
          text: response.data.message,
          confirmButtonColor: "#5eba86",
        });
        setModalShow(false);
        fetchData();
      });

    e.preventDefault();
  };

  // Delete Data
  const deleteData = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "green",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    if (isConfirm) {
      axios
        .delete(
          `${BACKEND_BASE_URL}/api/v3/rianaire/our-works-projects/delete/${id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: res.data.message,
            confirmButtonColor: "#5eba86",
          });
          fetchData();
        });
    }
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_between">
                  <h3>All Projects</h3>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => addNewData("Add")}
                  >
                    Add &nbsp;
                    <span>
                      <AiOutlinePlusCircle className="mb-1" />
                    </span>
                  </Button>
                </div>
              </div>
              {!isLoading && !error && tableData.length > 0 && (
                <div className="table-responsive custom_table">
                  <div className="table_fixed">
                    <table className="table table-hover ">
                      <thead>
                        <tr>
                          <th scope="col">Handle</th>
                          <th scope="col">Title</th>
                          <th scope="col">Featured Image</th>
                          <th scope="col">Is Popular</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData?.map((data, index) => (
                          <tr key={index}>
                            <td>
                              <Dropdown style={{ position: "unset" }}>
                                <Dropdown.Toggle>
                                  <BsThreeDotsVertical color="#000" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {/* view */}
                                  <Dropdown.Item
                                    onClick={() => {
                                      showSingleData("view", data);
                                    }}
                                  >
                                    View
                                  </Dropdown.Item>
                                  {/* edit */}
                                  <Dropdown.Item
                                    onClick={() =>
                                      fetchDataForEdit("Edit", data)
                                    }
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  {/* delete */}

                                  <Dropdown.Item
                                    onClick={() => deleteData(data.id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>{data.title}</td>

                            <td>
                              <img
                                className="img-thumbnail"
                                width={80}
                                height={50}
                                src={`${BACKEND_BASE_URL}${data.featured_image}`}
                                alt={data.title}
                              />
                            </td>

                            <td>
                              {data.is_popular == 1 ? (
                                <FaCheck
                                  style={{
                                    color: "white",
                                    backgroundColor: "green",

                                    padding: "2px 4px",
                                    borderRadius: "4px",
                                  }}
                                  size="1.6em"
                                />
                              ) : (
                                <AiOutlineClose
                                  style={{
                                    color: "white",
                                    backgroundColor: "red",

                                    padding: "2px 4px",
                                    borderRadius: "4px",
                                  }}
                                  size="1.6em"
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                        {!isLoading && !error && tableData?.length < 1 && (
                          <div className="text-center text-danger my-5 py-5">
                            <h1>No data Found</h1>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {isLoading && !error && <LinearProgress />}
            </div>

            <Modal
              show={modalShow}
              size={modalSize}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => setModalShow(false)}
            >
              <Modal.Header>
                <Modal.Title
                  className="text-white"
                  id="contained-modal-title-vcenter"
                >
                  {modalData}
                </Modal.Title>
                <Button
                  className="p-0 border-0 bg-danger"
                  onClick={() => setModalShow(false)}
                >
                  <CgClose
                    style={{
                      fontSize: "26px",
                    }}
                  />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {/* Add Modal section */}
                {modalData === "Add" && (
                  <div className="">
                    <Form onSubmit={store} noValidate validated={validated}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body">
                            <Row className="mb-3">
                              {/* work title */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Title<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="Title"
                                  ref={workTitle}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Title is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* work featured image */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Featured Image
                                  <span className="text-danger">*</span>
                                </Form.Label>

                                <Form.Control
                                  required
                                  type="file"
                                  ref={workFeaturedImage}
                                  onChange={handleImgPreview}
                                />

                                {files.map((file, key) => {
                                  return (
                                    <div key={key} className="Row">
                                      <span className="Filename">
                                        <img
                                          width={80}
                                          height={50}
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                        />
                                      </span>
                                    </div>
                                  );
                                })}

                                <Form.Control.Feedback type="invalid">
                                  Please choose an image
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* work multiple image */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Image Gallery (up to 10 images)
                                  <span className="text-danger">*</span>
                                </Form.Label>

                                <Form.Control
                                  type="file"
                                  name="images"
                                  onChange={onSelectFile}
                                  multiple
                                  ref={workMultipleImage}
                                />

                                <div className="row">
                                  {selectedImages &&
                                    selectedImages.length > 10 && (
                                      <p className="error">
                                        You can't upload more than 10 images!{" "}
                                        <br />
                                        <span>
                                          please delete{" "}
                                          <b> {selectedImages.length - 10} </b>{" "}
                                          of them{" "}
                                        </span>
                                      </p>
                                    )}

                                  {selectedImages &&
                                    selectedImages.map((image, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="col-md-3 mt-3"
                                        >
                                          <img
                                            src={URL.createObjectURL(image)}
                                            height="68"
                                            width="68"
                                            alt="upload"
                                          />
                                          <div className="d-flex justify-content-between">
                                            <p>{index + 1}</p>
                                            <AiFillCloseSquare
                                              className="cursor "
                                              size="1.5rem"
                                              color="red"
                                              onClick={() =>
                                                deleteHandler(image)
                                              }
                                            />
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                                <Form.Control.Feedback type="invalid">
                                  Please choose an image
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* category dropdown */}
                              <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label className="label fw-bold">
                                  Parent Category
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  required
                                  ref={workProjectCategoryDropdown}
                                  onChange={getSubCategory}
                                >
                                  <option value="">Select Category</option>
                                  {mainProjectCategory?.map((data, index) => {
                                    return (
                                      <option key={index} value={data.id}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Form.Group>

                              {/* sub-category dropdown */}

                              <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label className="label fw-bold">
                                  Sub Category
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                {/* <Form.Select
                                  aria-label="Default select example"
                                  required
                                  ref={workSubProjectCategoryDropdown}
                                >
                                  <option value="">Select Sub-Category</option>
                                  {subProjectCategory.length > 0 &&
                                    subProjectCategory?.map((data, index) => {
                                      return (
                                        <option key={index} value={data.id}>
                                          {data.name}
                                        </option>
                                      );
                                    })}
                                </Form.Select> */}

                                <FormControl sx={{ m: 1, width: 300 }}>
                                  <InputLabel id="demo-multiple-chip-label">
                                    Chip
                                  </InputLabel>
                                  <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={selectedSubCategory}
                                    onChange={handleChipChange}
                                    input={
                                      <OutlinedInput
                                        id="select-multiple-chip"
                                        label="Chip"
                                      />
                                    }
                                    renderValue={(selected) => (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: 0.5,
                                        }}
                                      >
                                        {selected.map((id) => {
                                          let name = subProjectCategory.find(
                                            (item) => item.id == id
                                          )?.name;
                                          return <Chip key={id} label={name} />;
                                        })}
                                      </Box>
                                    )}
                                    // MenuProps={MenuProps}
                                  >
                                    {subProjectCategory.map((data) => (
                                      <MenuItem key={data.id} value={data.id}>
                                        {data.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Form.Group>

                              {/* project client */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Client<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="client name"
                                  ref={workProjectClient}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Client is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* project type */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Project Type
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="type"
                                  ref={workType}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Type is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* Tools */}
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Tools<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                  required
                                  type="text"
                                  placeholder="type"
                                  ref={workTools}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Tools is required
                                </Form.Control.Feedback>
                              </Form.Group>

                              {/* is Popular */}
                              <Form.Group className="mb-3" as={Col} sm="12">
                                <Form.Check
                                  onChange={handleWorkChecklist}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label="is popular"
                                />
                              </Form.Group>

                              {/* project layout */}
                              {/* <Form.Group className="mb-3" as={Col} sm="12">
                                <div className="row image_layout border py-2">
                                  <p className="fw-bold">Image Layout:</p>
                                  <div className="col-sm-6 col-lg-3 mb-3 ">
                                    <Form.Check
                                      onChange={handleImageLayout}
                                      name="layout_class"
                                      type="radio"
                                      id="default-checkbox1"
                                      className="horizontal flex_center"
                                      label="Horizontal"
                                      value="horizontal"
                                    />
                                  </div>
                                  <div className="col-sm-6 col-lg-3 mb-3 ">
                                    <Form.Check
                                      onChange={handleImageLayout}
                                      name="layout_class"
                                      type="radio"
                                      id="default-checkbox2"
                                      className="vertical flex_center"
                                      label="Vertical"
                                      value="vertical"
                                    />
                                  </div>
                                  <div className="col-sm-6 col-lg-3 mb-3 ">
                                    <Form.Check
                                      onChange={handleImageLayout}
                                      name="layout_class"
                                      type="radio"
                                      id="default-checkbox3"
                                      className="big flex_center"
                                      label="Big"
                                      value="big"
                                    />
                                  </div>
                                  <div className="col-sm-6 col-lg-3 mb-3 ">
                                    <Form.Check
                                      onChange={handleImageLayout}
                                      name="layout_class"
                                      type="radio"
                                      id="default-checkbox4"
                                      className="normal flex_center"
                                      label="Normal"
                                      value="normal"
                                      defaultChecked
                                    />
                                  </div>
                                </div>
                              </Form.Group> */}

                              {/* work short desc */}
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom02"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Description
                                </Form.Label>

                                <RichTextEditor
                                  joditRef={shortDesc}
                                  getValue={getValue}
                                  value={value}
                                />
                              </Form.Group>
                            </Row>

                            <Button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0 d-flex justify-content-center"
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}

                {/* Edit modal section */}
                {modalData === "Edit" && (
                  <form onSubmit={updateData}>
                    <div className="content-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <div className="row py-3">
                            {/* work title */}
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Title
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Title"
                                ref={workTitle}
                                defaultValue={singleData?.title}
                              />
                              <Form.Control.Feedback type="invalid">
                                Title is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* featured Image */}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div>
                                  <label className="label fw-bold">
                                    Featured Image
                                  </label>
                                </div>
                                <div className="image-file">
                                  <input
                                    type="file"
                                    className="form-control"
                                    ref={workFeaturedImage}
                                    onChange={handleImgPreview}
                                  />

                                  {files.map((file, key) => {
                                    return (
                                      <div key={key} className="Row">
                                        <span className="Filename">
                                          <img
                                            width={80}
                                            height={50}
                                            src={URL.createObjectURL(file)}
                                            alt={file.name}
                                          />
                                        </span>
                                      </div>
                                    );
                                  })}

                                  {files.length == 0 && (
                                    <img
                                      width={80}
                                      src={`${BACKEND_BASE_URL}/${singleData?.featured_image}`}
                                      alt={workTitle}
                                      name="img"
                                      className="img-thumbnail me-2 my-2"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* multiple Image */}
                            {singleData?.image_galleries && (
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div>
                                    <label className="label fw-bold">
                                      Image Gallery
                                    </label>
                                  </div>
                                  <div className="image-file">
                                    <input
                                      type="file"
                                      className="form-control"
                                      ref={workMultipleImage}
                                      multiple
                                      onChange={onSelectFile}
                                      name="images"
                                    />

                                    <div className="row">
                                      {singleData?.image_galleries?.map(
                                        (data, index) => (
                                          <div
                                            className="col-md-3 mt-3"
                                            key={index}
                                          >
                                            <img
                                              src={`${BACKEND_BASE_URL}/${data?.image}`}
                                              height="68"
                                              width="68"
                                              alt="upload"
                                            />

                                            <div className="d-flex justify-content-between">
                                              <p>{index + 1}</p>
                                              <AiFillCloseSquare
                                                className="cursor "
                                                size="1.5rem"
                                                color="red"
                                                onClick={() => {
                                                  deleteGalleryImage(data.id);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>

                                    <div className="row">
                                      {selectedImages &&
                                        selectedImages.map((image, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="image col-md-3"
                                            >
                                              <img
                                                src={URL.createObjectURL(image)}
                                                height="68"
                                                width="68"
                                                alt="upload"
                                              />
                                              <div className="d-flex justify-content-between">
                                                <p>{index + 1}</p>
                                                <AiFillCloseSquare
                                                  className="cursor "
                                                  size="1.5rem"
                                                  color="red"
                                                  onClick={() =>
                                                    deleteHandler(image)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* category dropdown */}
                            <Form.Group as={Col} md="6" className="mb-3">
                              <Form.Label>
                                Parent Category
                                <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                required
                                ref={workProjectCategoryDropdown}
                                onChange={getSubCategory}
                              >
                                <option value="">Select Category</option>
                                {mainProjectCategory?.map((data, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={data.id}
                                      selected={
                                        singleData?.parent_category_id ==
                                        data.id
                                          ? "selected"
                                          : ""
                                      }
                                    >
                                      {data.name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>

                            {/* sub-category dropdown */}

                            <Form.Group as={Col} md="6" className="mb-3">
                              <Form.Label>
                                Sub Category
                                <span className="text-danger">*</span>
                              </Form.Label>
                              {/* <Form.Select
                                aria-label="Default select example"
                                required
                                ref={workSubProjectCategoryDropdown}
                              >
                                <option value="">Select Sub-Category</option>
                                {subProjectCategory.length > 0 &&
                                  subProjectCategory?.map((data, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={data.id}
                                        selected={
                                          singleData?.category_id == data.id
                                            ? "selected"
                                            : ""
                                        }
                                      >
                                        {data.name}
                                      </option>
                                    );
                                  })}
                              </Form.Select> */}

                              <FormControl sx={{ m: 1, width: 300 }}>
                                <InputLabel id="demo-multiple-chip-label">
                                  Chip
                                </InputLabel>
                                <Select
                                  labelId="demo-multiple-chip-label"
                                  id="demo-multiple-chip"
                                  multiple
                                  value={selectedSubCategory}
                                  onChange={handleChipChange}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label="Chip"
                                    />
                                  }
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected.map((id) => {
                                        let name = subProjectCategory.find(
                                          (item) => item.id == id
                                        )?.name;
                                        return <Chip key={id} label={name} />;
                                      })}
                                    </Box>
                                  )}
                                  // MenuProps={MenuProps}
                                >
                                  {subProjectCategory.map((data) => (
                                    <MenuItem key={data.id} value={data.id}>
                                      {data.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Form.Group>

                            {/* project client */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Client
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="client name"
                                ref={workProjectClient}
                                defaultValue={singleData?.client}
                              />
                              <Form.Control.Feedback type="invalid">
                                Client is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* project type */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Project Type
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="type"
                                ref={workType}
                                defaultValue={singleData?.work_type}
                              />
                              <Form.Control.Feedback type="invalid">
                                Type is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* Tools */}
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="mb-3"
                            >
                              <Form.Label className="label fw-bold">
                                Tools
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="type"
                                ref={workTools}
                                defaultValue={singleData?.tools}
                              />
                              <Form.Control.Feedback type="invalid">
                                Tools is required
                              </Form.Control.Feedback>
                            </Form.Group>

                            {/* is Popular */}
                            <Form.Group className="mb-3" as={Col} sm="12">
                              <Form.Check
                                type="checkbox"
                                id="default-checkbox"
                                label="is popular"
                                ref={workCheckList}
                                defaultChecked={singleData?.is_popular == "1"}
                                onChange={handleWorkChecklist}
                              />
                            </Form.Group>

                            {/* project layout */}
                            {/* <Form.Group className="mb-3" as={Col} sm="12">
                              <div className="row image_layout border py-2">
                                <p className="fw-bold">Image Layout:</p>
                                <div className="col-sm-6 col-lg-3 mb-3 ">
                                  <Form.Check
                                    onChange={handleImageLayout}
                                    name="layout_class"
                                    type="radio"
                                    id="default-checkbox1"
                                    className="horizontal flex_center"
                                    label="Horizontal"
                                    defaultValue="horizontal"
                                    defaultChecked={
                                      singleData?.layout_class == "horizontal"
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                </div>
                                <div className="col-sm-6 col-lg-3 mb-3 ">
                                  <Form.Check
                                    onChange={handleImageLayout}
                                    name="layout_class"
                                    type="radio"
                                    id="default-checkbox2"
                                    className="vertical flex_center"
                                    label="Vertical"
                                    defaultValue="vertical"
                                    defaultChecked={
                                      singleData?.layout_class == "vertical"
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                </div>
                                <div className="col-sm-6 col-lg-3 mb-3 ">
                                  <Form.Check
                                    onChange={handleImageLayout}
                                    name="layout_class"
                                    type="radio"
                                    id="default-checkbox3"
                                    className="big flex_center"
                                    label="Big"
                                    defaultValue="big"
                                    defaultChecked={
                                      singleData?.layout_class == "big"
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                </div>
                                <div className="col-sm-6 col-lg-3 mb-3 ">
                                  <Form.Check
                                    onChange={handleImageLayout}
                                    name="layout_class"
                                    type="radio"
                                    id="default-checkbox4"
                                    className="normal flex_center"
                                    label="Normal"
                                    defaultValue="normal"
                                    defaultChecked={
                                      singleData?.layout_class == "normal"
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                </div>
                              </div>
                            </Form.Group> */}

                            {/* work short Description */}
                            <div className="col-md-12">
                              <div className="form-group mb-3">
                                <Form.Label className="label fw-bold">
                                  Short Description
                                </Form.Label>

                                <RichTextEditor
                                  joditRef={shortDesc}
                                  value={singleData?.description}
                                  getValue={getValue}
                                />
                              </div>
                            </div>

                            {/* Submit button */}
                            <button
                              type="submit"
                              className="btn-submit mt-5 rounded-3 border-0 flex_center"
                            >
                              <MdSystemUpdateAlt className="me-2" />
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}

                {/* View Modal section */}
                {modalData === "View" && (
                  <>
                    <h4 className="py-4">Name: {singleData?.title}</h4>
                    <div className="text-center mb-4">
                      <h4>Featured Image</h4>
                      <img
                        className="img-thumbnail"
                        src={`${BACKEND_BASE_URL}${singleData?.featured_image}`}
                        alt=""
                      />
                    </div>

                    <h4 className="text-center mb-4">Image Gallery</h4>

                    <div className="row">
                      {singleData?.image_galleries.map((data, index) => (
                        <div className="col-md-4 mb-4" key={index}>
                          <img
                            className="img-thumbnail "
                            src={`${BACKEND_BASE_URL}/${data?.image}`}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>

                    <h4>
                      Parent Category Name:{" "}
                      {singleData?.project_our_work_parent_category?.name}
                    </h4>
                    <h4>
                      Category Name:{" "}
                      {singleData?.project_our_work_category?.name}
                    </h4>
                    <h5>Client : {singleData?.client}</h5>
                    <h5>Work Type : {singleData?.work_type}</h5>
                    <h5>Tools : {singleData?.tools}</h5>
                    <div className="mt-2">
                      {Parse(`${singleData?.description}`)}
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setModalShow(false)}
                  className="btn-danger"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOurProjectWorks;
