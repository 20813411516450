import Echo from "laravel-echo";
import pusher from "pusher-js";

let Pusher = pusher;
let token = JSON.parse(localStorage.getItem("ACCESS_TOKEN"));
export const echo = new Echo({
  broadcaster: "pusher",
  authEndpoint: `http://192.168.68.107:8000/broadcasting/auth`,
  // host: "http://192.168.68.107:8000",
  auth: {
    headers: {
      Authorization: `Bearer ${token?.value}`,
      Accept: "application/json",
    },
  },
  key: "0611b058a525a58b31ea",
  cluster: "mt1",
  forceTLS: true,
});