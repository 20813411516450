import Cookie from "cookie-universal";

function getCookie(name) {
  const cookies = Cookie();
  return cookies.get(name);
}
function setCookie(name, value, option) {
  const cookies = Cookie();
  return cookies.set(name, value, option);
}
export default getCookie;
