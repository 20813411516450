
import React, { useEffect, useRef, useState } from "react";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import axios from "axios";
import { echo } from "../../../utils/pusherEcho";
import { Link, Outlet } from "react-router-dom";

const AdminInbox = () => {
  const clientMsg = useRef();
  const userId = useRef();
  const groupId = useRef();
  const div = useRef();
  const groupChatVal = useRef();
  const chatBox = useRef();
  const singleOrderChatBoxRef = useRef();
  const supportConversationChatBoxRef = useRef();

  const [searchUserInfo, setSearchUserInfo] = useState([]);
  const [supportConversation, setSupportConversation] = useState([]);

  const [latestUserList, setLatestUserList] = useState([]);
  const [active, setActive] = useState(null);
  const [fetchUser, setFetchUser] = useState(null);
  const [searchVal, setSearchValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);

  const [orderChatList, setOrderChatList] = useState([]);
  const [singleChatSlug, setSingleChatSlug] = useState();
  const [currentUserInfo, setCurrentUserInfo] = useState();
  const [orderChatMessageList, setOrderChatMessageList] = useState([]);
  const [singleConversatrionAMessage, setSupportConversationMessage] = useState(
    []
  );

  const [orderConversation, setOrderConversation] = useState([]);
  const [status, setStatus] = useState("initial");

  const [singleChatInfo, setSingleChatInfo] = useState([]);

  // ================== Load More ========================
  let per_page = 5;
  const [currentPageNum, setCurrentPageNum] = useState({
    support_conversations: "",
    order_conversations: "",
  });
  const [lastPageNumber, setLastPageNumber] = useState({
    support_conversations: "",
    order_conversations: "",
  });

  const fetchOrderChat = () => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/conversation/all?per_page=${per_page}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setOrderChatList(res.data?.order_conversations?.data);
        setCurrentPageNum({
          ...currentPageNum,
          order_conversations: res.data?.order_conversations?.current_page,
        });
        setLastPageNumber({
          ...lastPageNumber,
          order_conversations: res.data?.order_conversations?.last_page,
        });
      });
  };

  const fetchSupportConversation = () => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v3/rianaire/chat/support/conversation/all?per_page=${per_page}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSupportConversation(res.data?.support_conversations?.data);
        setCurrentPageNum({
          ...currentPageNum,
          support_conversations: res.data?.support_conversations?.current_page,
        });
        setLastPageNumber({
          ...lastPageNumber,
          support_conversations: res.data?.support_conversations?.last_page,
        });
      });
  };

  const LoadMoreOrderChatList = () => {
    if (currentPageNum != lastPageNumber) {
      var pageNum = currentPageNum + 1;
    }
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/conversation/all?page=${pageNum}&per_page=${per_page}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setOrderChatList([
          ...orderChatList,
          ...res.data?.order_conversations?.data,
        ]);
        setCurrentPageNum(pageNum);
      });
  };

  const handleOrderList = (chat) => {
    console.log("called");
    setSingleChatSlug(chat.slug);
    setSingleChatInfo(chat);
    setSearchUserInfo([]);
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/conversation/${chat.slug}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setOrderChatMessageList(res.data?.order_conversation_messages?.data);
        setOrderConversation(res.data?.conversation);
        setCurrentUserInfo(res.data?.user);
        singleOrderChatBoxRef.current.style.display = "block";
        singleOrderChatBoxRef.current.classList.add("open");
      });
  };

  // Support Conversation
  const handleSupportConversationList = (chat) => {
    console.log("support called");
    setSingleChatSlug(chat.slug);
    setSingleChatInfo(chat);
    setSearchUserInfo([]);
    axios
      .post(
        `${BACKEND_BASE_URL}/api/v3/support-chat/support-conversation/${chat.slug}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        setSupportConversationMessage(
          res.data?.order_conversation_messages?.data
        );
        setOrderConversation(res.data?.conversation);
        setCurrentUserInfo(res.data?.user);
        supportConversationChatBoxRef.current.style.display = "block";
        supportConversationChatBoxRef.current.classList.add("open");
      });
  };

  // groupchat submit to backend
  const storeGroupChat = (e) => {
    const formdata = new FormData();
    formdata.append("message", groupChatVal.current.value);
    formdata.append("group_id", groupId.current.value);
    if (groupChatVal.current.value != "") {
      axios.post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/group-chat/store`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      );

      // .then((response) => {
      //   if (response.data?.status == 200) {

      // });
    }

    e.preventDefault();
  };

  const handleKeyPress = (event) => {
    // const formdata = new FormData();
    // formdata.append("name", searchVal);
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/conversation/search/${searchVal}?take=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((res) => {
        if (res.data?.status == 200) {
          if (res.data?.order_conversations.length == 0) {
            setFetchUser(null);
          }
          setSearchUserInfo(res.data?.order_conversations);
        }
      });
    event.preventDefault();
  };

  const handleClient = (id) => {
    setMessages([]);
    axios
      .get(`${BACKEND_BASE_URL}/api/v2/rianaire/chat/search/user/${id}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        setFetchUser(res.data?.user);
        setMessages(res.data?.messages);
        setSearchUserInfo([]);
        setSearchValue("");
        div.current?.scrollIntoView({ behavior: "smooth", block: "end" });
        chatBox.current.style.display = "block";
        chatBox.current.classList.add("open");
      });
  };

  console.log(orderChatMessageList);
  // form submit to backend
  const storeChat = (e) => {
    setStatus("loading");
    e.preventDefault();

    const formdata = new FormData();
    formdata.append("conversation_id", singleChatInfo.id);
    formdata.append("message", clientMsg.current.value);
    formdata.append("type", "message");

    setOrderChatMessageList((prev) => [
      {
        message: clientMsg.current.value,
        type: "message",
        pending: true,
        conversation_user: {
          user_data: currentUserInfo,
        },
      },
      ...prev,
    ]);

    if (clientMsg.current.value != "") {
      axios
        .post(
          `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/conversation/send-message`,
          formdata,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getLocalStorageWithExpiry(
                "ACCESS_TOKEN"
              )}`,
            },
          }
        )

        .then((response) => {
          if (response.data?.status === 200) {
            setOrderChatMessageList(response.data?.conversation?.messages);
            setOrderConversation(response.data?.conversation);

            e.target.reset();

            setStatus("initial");
          }
        });
    }
  };

  const sortedItems = [...latestUserList].sort((a, b) => {
    // Convert the updated_at strings to Date objects for comparison
    const dateA = new Date(a.msg_time);
    const dateB = new Date(b.msg_time);

    // Compare the Date objects to determine the order
    // Use -1 to sort in descending order; use 1 to sort in ascending order
    return dateB - dateA; // Sorting in descending order (newest first)
    // return dateA - dateB; // Sorting in ascending order (oldest first)
  });

  //   const allUserMessage = () => {
  //     axios
  //       .get(
  //         `${BACKEND_BASE_URL}/api/v2/rianaire/chat/all-messages/${userId.current.value}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${getLocalStorageWithExpiry(
  //               "ACCESS_TOKEN"
  //             )}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         setMessages(res.data?.messages);
  //       });
  //   };

  useEffect(() => {
    const userList = setInterval(() => {
      //   allUserMessage();
    }, 5000);

    return () => {
      clearInterval(userList); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    };
  }, []);

  function collapseChatbox() {
    if (singleOrderChatBoxRef.current.classList.contains("open")) {
      singleOrderChatBoxRef.current.classList.remove("open");
    } else {
      singleOrderChatBoxRef.current.classList.add("open");
    }
  }
  function closeChatBox() {
    singleOrderChatBoxRef.current.style.display = "none";
  }

  // function collapsesingleOrderChatBox() {
  //   if (singleOrderChatBoxRef.current.classList.contains("open")) {
  //     singleOrderChatBoxRef.current.classList.remove("open");
  //   } else {
  //     singleOrderChatBoxRef.current.classList.add("open");
  //   }
  // }
  // function closesingleOrderChatBox() {
  //   singleOrderChatBoxRef.current.style.display = "none";
  // }

  console.log(orderChatMessageList);


  // console.log(singleChatInfo.id)
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />
        <div className="">
          <div className="d-flex justify-content-start align-items-center gap-3">
            <Link to="/admin/inbox/order-conversation" className="bg-white py-3 px-5">Order conversation</Link>
            <Link to="/admin/inbox/support-conversation" className="bg-white py-3 px-5">Support conversation</Link>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminInbox;
