import axios from "axios";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../Component/AdminDashboardNavbar";
import getCookie from "../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../Component/GlobalVariables";
import RichTextEditor from "../../../Component/RichTextEditor";
import { getLocalStorageWithExpiry } from "../../../Component/LocalStorageWithExpiry";
// import DescriptionEditor from "../../../Component/DescriptionEditor";

const AdminCaseStudyAdd = () => {
  const title = useRef();
  const image = useRef();
  const description = useRef();
  const [editorData, setEditorData] = useState("");
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  // Image Preview
  const [files, setFile] = useState([]);

  const handleImgPreview = (e) => {
    let allfiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      allfiles.push(e.target.files[i]);
    }
    if (allfiles.length > 0) {
      setFile(allfiles);
    }
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("title", title.current.value);
    formdata.append("image", image.current.files[0]);
    formdata.append("description", description.current.value);
    formdata.append("category_id", description.current.value);
    formdata.append("details_title[]", description.current.value);
    formdata.append("details_image[]", description.current.value);
    formdata.append("details_description[]", description.current.value);

    axios
      .post(`${BACKEND_BASE_URL}/api/v2/rianaire/case-study/store`, formdata, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((response) => {
        setFile([]);
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
        }
      });
    e.preventDefault();
  };

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to="/admin/case-study">
                    <Button variant="success" size="sm">
                      View All News &nbsp;
                    </Button>
                  </Link>
                </div>
                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* add News title */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              News Title
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="News Title"
                              ref={title}
                            />
                          </Form.Group>

                          {/* add Image */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Featured Image
                            </Form.Label>
                            <Form.Control
                              type="file"
                              onChange={handleImgPreview}
                              ref={image}
                            />
                            {files.map((file, index) => {
                              return (
                                <div key={index} className="Row">
                                  <span className="Filename">
                                    <img
                                      width={80}
                                      height={50}
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                    />
                                  </span>
                                </div>
                              );
                            })}
                          </Form.Group>

                          {/* Description */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom02"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Description
                            </Form.Label>

                            <RichTextEditor
                              joditRef={description}
                              getValue={getValue}
                              value={value}
                            />
                          </Form.Group>

                          {/* <DescriptionEditor setEditorData={setEditorData} editorData={editorData} /> */}

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCaseStudyAdd;
