"use client";
import { BsThreeDots } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { ImEqualizer } from "react-icons/im";

import { useEffect, useRef, useState } from "react";
import InboxDropdownButton from "../../../../Component/InboxDropdownButton";
import { cn } from "../../../../utils/cn";
import { Link } from "react-router-dom";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";
import moment from "moment";
import "../adminInbox.css"
import { echo } from "../../../../utils/pusherEcho";

export default function InboxSidebar({ type, parentReload }) {
  const [status, setStatus] = useState("initial");
  const [activeTab, setActiveTab] = useState(false);
  const [conversation, setConversation] = useState([]);

  let per_page = 5;
  const [currentPageNum, setCurrentPageNum] = useState({
    support_conversations: 0,
    order_conversations: 0,
  });
  const [lastPageNumber, setLastPageNumber] = useState({
    support_conversations: [],
    order_conversations: [],
  });

  const fetchConversation = () => {
    let url = null;

    switch (type) {
      case "order":
        url = `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/conversation/all?per_page=${per_page}`;
        break;
      case "support":
        url = `${BACKEND_BASE_URL}/api/v3/rianaire/chat/support/conversation/all?per_page=${per_page}`;
        break;
      default:
        break;
    }

    axios
      .get(`${url}`, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (type === "order") {
          setConversation(res.data?.order_conversations?.data);
          setCurrentPageNum({
            ...currentPageNum,
            order_conversations: res.data?.order_conversations?.current_page,
          });
          setLastPageNumber({
            ...lastPageNumber,
            order_conversations: res.data?.order_conversations?.last_page,
          });
        } else {
          setConversation(res.data?.support_conversations?.data);
          setCurrentPageNum({
            ...currentPageNum,
            support_conversations:
              res.data?.support_conversations?.current_page,
          });
          setLastPageNumber({
            ...lastPageNumber,
            support_conversations: res.data?.support_conversations?.last_page,
          });
        }
      });
  };

  const LoadMoreOrderChatList = () => {
    // Check if more data needs to be loaded
    if (
      currentPageNum.order_conversations < lastPageNumber.order_conversations
    ) {

      // Increment the specific property
      const newPageNum = currentPageNum.order_conversations + 1;

      axios
        .get(
          `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/conversation/all?page=${newPageNum}&per_page=${per_page}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
            },
          }
        )
        .then((res) => {
          setConversation([
            ...conversation,
            ...res.data?.order_conversations?.data,
          ]);
          setCurrentPageNum(prevState => ({
            ...prevState,
            order_conversations: newPageNum
          }));
        });
    } else if (currentPageNum.support_conversations < lastPageNumber.support_conversations) {
      // Increment the specific property
      const newPageNum = currentPageNum.order_conversations + 1;

      axios
        .get(
          `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/support/all?page=${newPageNum}&per_page=${per_page}`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
            },
          }
        )
        .then((res) => {
          setConversation([
            ...conversation,
            ...res.data?.support_conversations?.data,
          ]);
          setCurrentPageNum(prevState => ({
            ...prevState,
            support_conversations: newPageNum
          }));
        });
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }

  const debouncedSearch = debounce((value) => {
    let url = null;

    switch (type) {
      case "order":
        url = `${BACKEND_BASE_URL}/api/v3/rianaire/chat/order/conversation/search/${value}?take=10`;
        break;
      case "support":
        url = `${BACKEND_BASE_URL}/api/v3/rianaire/chat/support/conversation/search/${value}?take=10`;
        break;
      default:
        break;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${getLocalStorageWithExpiry("ACCESS_TOKEN")}`,
        },
      })
      .then((res) => {
        if (type === "order") {
          setConversation(res.data?.order_conversations);

        } else {
          // Handle support type if needed
        }
      });
  }, 500);

  useEffect(() => {
    fetchConversation();
  }, [type, parentReload]);

    // Pusher
    useEffect(() => {
      let channel = echo.private(`conversation.${conversation.id}`);
      channel.listen(".message", function (data) {
        console.log("pusher data", data);
      });
    }, [conversation]);
console.log("conversation.id",conversation)
  return (
    <div
      style={{
        width: "33.3333%",
        borderRight: "1px solid rgba(140, 140, 140, 0.2)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 10px 1px",
        }}
      >
        <h4>Inbox</h4>
        <InboxDropdownButton dropdownIcon={<BsThreeDots size={22} />}>
          <li
            style={{
              cursor: "pointer",
              padding: "8px 16px",
              ":hover": { backgroundColor: "#D1D5DB" },
            }}
          >
            option 1
          </li>
          <li
            style={{
              cursor: "pointer",
              padding: "8px 16px",
              ":hover": { backgroundColor: "#D1D5DB" },
            }}
          >
            option 2
          </li>
          <li
            style={{
              cursor: "pointer",
              padding: "8px 16px",
              ":hover": { backgroundColor: "#D1D5DB" },
            }}
          >
            option 3
          </li>
        </InboxDropdownButton>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTop: "1px solid rgba(140, 140, 140, 0.2)",
          padding: "10px",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#EDF3F8",
            padding: "3px 0"
          }}
        >
          <IoSearch style={{ position: "absolute", left: "6px", top: "7px" }} />
          <input
            type="text"
            placeholder="Search messages"
            style={{
              width: "100%",
              border: "none",
              backgroundColor: "transparent",
              padding: "0.5px 23px",
              paddingLeft: "2rem",
              fontSize: "0.875rem",
            }}
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        </div>
        <div
          style={{
            cursor: "pointer",
            borderRadius: "2px",
            padding: "0.5px 8px",
            transition: "all 0.3s",
            ":hover": { backgroundColor: "#F3F3F3" },
          }}
        >
          <ImEqualizer style={{ display: "inline" }} />{" "}
          <span style={{ fontSize: "0.875rem" }}>Filter</span>
        </div>
      </div>
      <div style={{
        maxHeight: "550px",
        overflowY: "auto"
      }}>
        {conversation.map((orderList) => (
          <Link key={orderList.id} to={`${orderList.slug}`} state={{ orderList: orderList }}>
            <div onClick={() => setActiveTab(orderList.id)} className={`${orderList.id === activeTab ? "active_conversation" : "not_active_conversation"}`}
              style={{
                display: "flex",
                gap: "16px",
                borderTop: "1px solid rgba(140, 140, 140, 0.2)",
                backgroundColor: orderList.id === activeTab ? "#EDF3F8" : "",
                padding: "10px 16px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  height: "64px",
                  minWidth: "64px",
                  maxWidth: "64px",
                  borderRadius: "50%",
                  backgroundColor: "#F87171",
                }}
              ></div>

              <div className="d-flex w-100 justify-content-between"
              >
                <div>
                  <h3 style={{ fontSize: "1rem" }}>{orderList?.name}</h3>
                  <p className="line-clamp-2"
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: "2",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      fontSize: "0.875rem",
                      color: "#6B7280",
                    }}
                  >
                    {orderList?.last_message}
                  </p>
                </div>
                <div style={{ height: "24px" }} className="">
                  <span
                    style={{
                      display: "block",
                      paddingTop: "7px",
                      fontSize: "0.875rem",
                      minWidth: "max-content"
                    }}
                  >
                    {moment(orderList?.updated_at).format("hh:mm A")}
                  </span>
                </div>
              </div>

            </div>
          </Link>
        ))}
      </div>


      {/* <button onClick={() => LoadMoreOrderChatList()}>load more</button> */}

    </div>
  );
}
