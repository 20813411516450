import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";
import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { ToastAlert } from "../../../../Component/ToastAlert";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminServiceCostingAdd = () => {
  const serviceName = useRef();
  const subServiceName = useRef();
  const childService = useRef();

  const [mainServices, setMainServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [childServices, setChildServices] = useState([]);

  // const [dropdownVal, setDropdownVal] = useState();

  const [dynamicdropdown, setDynamicdropdown] = useState([]);

  const [serviceTypeBox, setServiceTypeBox] = useState(true);
  const [sectionTypeBox, setSectionTypeBox] = useState(false);
  const [wordpressWebsiteType, setWordpressWebsiteType] = useState(false);
  const [noOfPageBox, setNoOfPageBox] = useState(false);
  const [serverType, setServerType] = useState(false);
  const [packageType, setPackageType] = useState(false);

  const handleServiceType = (e) => {
    if (
      // dev&Tech -> wordpress -> Page Design
      serviceName.current.value == 1 &&
      subServiceName.current.value == 1 &&
      childService.current.value == 1
    ) {
      setSectionTypeBox(false);
      setNoOfPageBox(true);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      //dev&Tech -> wordpress -> Complete Website
      serviceName.current.value == 1 &&
      subServiceName.current.value == 1 &&
      childService.current.value == 2
    ) {
      setSectionTypeBox(false);
      setNoOfPageBox(true);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> shopify -> Section Design
      serviceName.current.value == 1 &&
      subServiceName.current.value == 2 &&
      childService.current.value == 4
    ) {
      setNoOfPageBox(true);
      setSectionTypeBox(true);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> shopify -> Complete Website
      serviceName.current.value == 1 &&
      subServiceName.current.value == 2 &&
      childService.current.value == 5
    ) {
      setNoOfPageBox(true);
      setSectionTypeBox(false);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> Web programming -> React JS , React Laravel, PHP Laravel
      serviceName.current.value == 1 &&
      subServiceName.current.value == 4 &&
      (childService.current.value == 7 ||
        childService.current.value == 8 ||
        childService.current.value == 9)
    ) {
      setWordpressWebsiteType(true);
      setSectionTypeBox(false);
      setNoOfPageBox(true);
      setServerType(false);
      setPackageType(false);
    }

    if (
      // dev&Tech -> Website Maintenance -> Server installation
      serviceName.current.value == 1 &&
      subServiceName.current.value == 5 &&
      childService.current.value == 12
    ) {
      setNoOfPageBox(false);
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(true);
      setPackageType(false);
    }

    if (
      // marketing -> digital marketing -> Social Media Management
      serviceName.current.value == 3 &&
      subServiceName.current.value == 11 &&
      childService.current.value == 21
    ) {
      setNoOfPageBox(false);
      setSectionTypeBox(false);
      setWordpressWebsiteType(true);
      setServerType(false);
      setPackageType(false);
    }
    if (
      // dev&Tech -> Wordpress -> Custom Page
      serviceName.current.value == 1 &&
      subServiceName.current.value == 1 &&
      childService.current.value == 32
    ) {
      setNoOfPageBox(true);
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setPackageType(false);
    }

    // else {
    //   setNoOfPageBox(true);
    //   setSectionTypeBox(false);
    //   setWordpressWebsiteType(true);
    //   setServerType(false);
    //   setPackageType(false);
    // }
  };

  // Dynamic Input Field 1

  const [inputFields, setInputFields] = useState([
    {
      sectiontype: null,
      totalPages: "",
      usdPrice: "",
      bdtPrice: "",
      duration: "",
    },
  ]);

  const handleFormChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;

    setInputFields(data);
  };

  const handleFileChange = (index, event) => {
    let data = [...inputFields];
    data[index][event.target.name] = event.target.files[0];
    setInputFields(data);
  };

  const addFields = () => {
    let newfield = {
      sectiontype: null,
      totalPages: "",
      usdPrice: "",
      bdtPrice: "",
      duration: "",
    };
    setInputFields([...inputFields, newfield]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const fetchMainServices = async () => {
    await axios.get(`${BACKEND_BASE_URL}/api/v2/main-services`).then((res) => {
      setMainServices(res.data?.main_services);
    });
  };

  const fetchSubServices = (e) => {
    setSubServices([]);
    e.preventDefault();
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/sub-services/${serviceName.current.value}`
      )
      .then((res) => {
        setSubServices(res.data?.sub_services);
      });
  };

  const fetchChildService = () => {
    // UI/UX design
    if (subServiceName.current.value == 3) {
      setNoOfPageBox(true);
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setServiceTypeBox(true);
      setPackageType(false);
    }
    //  Website Redesign
    else if (subServiceName.current.value == 6) {
      setNoOfPageBox(true);
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setServiceTypeBox(true);
      setPackageType(false);
    }

    // else if (
    //   subServiceName.current.value == 13 ||
    //   subServiceName.current.value == 14
    // ) {
    //   setNoOfPageBox(false);
    //   setSectionTypeBox(false);
    //   setWordpressWebsiteType(false);
    //   setServerType(false);
    //   setServiceTypeBox(false);
    //   setPackageType(true);
    // }
    else {
      setNoOfPageBox(false);
      setSectionTypeBox(false);
      setWordpressWebsiteType(false);
      setServerType(false);
      setServiceTypeBox(true);
      setPackageType(false);
    }

    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/child-services/${serviceName.current.value}/${subServiceName.current.value}`
      )
      .then((res) => {
        setChildServices(res.data?.child_services);
      });
  };

  const fetchSectionType = () => {
    axios
      .get(
        `${BACKEND_BASE_URL}/api/v2/dynamic-dropdown/${serviceName.current.value}/${subServiceName.current.value}/${childService.current.value}`
      )
      .then((res) => {
        setDynamicdropdown(res.data?.dynamic_dropdown_info);
      });
  };

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("main_service_id", serviceName.current.value);
    formdata.append("sub_service_id", subServiceName.current.value);
    if (childService.current) {
      formdata.append("child_service_id", childService.current.value);
    }

    inputFields.forEach((item) => {
      if (item.sectiontype != null) {
        formdata.append("section_type[]", item.sectiontype);
      }
      formdata.append("total_page[]", item.totalPages);
      formdata.append("usd_price[]", item.usdPrice);
      formdata.append("bdt_price[]", item.bdtPrice);
      formdata.append("duration[]", item.duration);
    });

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/new-service-cost/store`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )

      .then((response) => {
        if (response.data?.status === 200) {
          ToastAlert.fire({
            title: "Submitted Successfully",
          });

          // setInputFields([
          //   {
          //     sectiontype: null,
          //     totalPages: "",
          //     usdPrice: "",
          //     bdtPrice: "",
          //     duration: "",
          //     additionalServices: "",
          //     additionalAppPlugin: "",
          //     dynamicModule: "",
          //   },
          // ]);
          // setMainServices([]);
          // setSubServices([]);
          // setChildServices([]);
          // setDynamicdropdown([]);
          setServiceTypeBox(true);
          fetchMainServices();
        }
      });
    e.preventDefault();
  };

  useEffect(() => {
    fetchMainServices();
  }, []);

  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <>
                  <div className="card-header flex_between">
                    <h3>Service Costing</h3>
                    {JSON.parse(
                      localStorage.getItem("LOGGED_IN_USER_PERMISSION_INFO")
                    )?.map(
                      (data, index) =>
                        data?.permission_name === "create-service-cost" && (
                          <Link to="/admin/service-costing" key={index}>
                            <Button variant="success" size="sm">
                              All Service Costing&nbsp;
                              <span>
                                <AiOutlinePlusCircle className="mb-1" />
                              </span>
                            </Button>
                          </Link>
                        )
                    )}
                  </div>

                  <div className="">
                    <Form onSubmit={storeData}>
                      <div className="content-wrapper">
                        <div className="card">
                          <div className="card-body table_fixed">
                            <div className="row py-3">
                              {/* Department */}
                              <Form.Group
                                as={Col}
                                md="6"
                                lg="4"
                                xl="4"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Department
                                </Form.Label>
                                <Form.Select
                                  required
                                  aria-label="language example "
                                  className=""
                                  ref={serviceName}
                                  onChange={(e) => {
                                    fetchSubServices(e);
                                  }}
                                >
                                  <option>Select Service</option>
                                  {mainServices?.map((parentCategory) => (
                                    <option
                                      key={parentCategory.id}
                                      value={parentCategory.id}
                                    >
                                      {parentCategory.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>

                              {/* Service Name */}
                              <Form.Group
                                as={Col}
                                md="6"
                                lg="4"
                                xl="4"
                                controlId="validationCustom01"
                                className="mb-3"
                              >
                                <Form.Label className="label fw-bold">
                                  Service Name
                                </Form.Label>
                                <Form.Select
                                  required
                                  aria-label="language example "
                                  className=""
                                  ref={subServiceName}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    fetchChildService();
                                    fetchSectionType(e);
                                  }}
                                >
                                  <option value="" selected>
                                    Select Sub Service
                                  </option>
                                  {subServices.length > 0 &&
                                    subServices?.map((subCategory) => (
                                      <option
                                        key={subCategory.id}
                                        value={subCategory.id}
                                      >
                                        {subCategory.name}
                                      </option>
                                    ))}
                                </Form.Select>
                              </Form.Group>
                              {/* Service Type */}

                              {serviceTypeBox == true && (
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  lg="4"
                                  xl="4"
                                  controlId="validationCustom01"
                                  className="mb-3"
                                >
                                  <Form.Label className="label fw-bold">
                                    Service Type
                                  </Form.Label>
                                  <Form.Select
                                    required
                                    aria-label="language example "
                                    className=""
                                    ref={childService}
                                    onChange={(e) => {
                                      handleServiceType(e);
                                      fetchSectionType();
                                    }}
                                  >
                                    <option value="0" selected>
                                      Select Service Type
                                    </option>

                                    {childServices?.map((data) => (
                                      <option key={data.id} value={data.id}>
                                        {data.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Form.Group>
                              )}
                            </div>

                            {inputFields?.map((input, index) => {
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="p-3 mb-2"
                                    style={{
                                      border: "1px solid #d1d1d1",
                                      backgroundColor: "#f3f3f3",
                                    }}
                                  >
                                    <Row>
                                      {/* Section Type */}
                                      {sectionTypeBox == true && (
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          lg="4"
                                          xl="3"
                                          controlId="validationCustom01"
                                          className="mb-3"
                                        >
                                          <Form.Label className="label fw-bold">
                                            Section Type
                                          </Form.Label>
                                          <Form.Select
                                            aria-label="language example "
                                            className=""
                                            name="sectiontype"
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          >
                                            <option value="">
                                              Select Section Type
                                            </option>

                                            {dynamicdropdown?.map((data) => (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                              >
                                                {data.name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </Form.Group>
                                      )}

                                      {serverType == true && (
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          lg="4"
                                          xl="3"
                                          controlId="validationCustom01"
                                          className="mb-3"
                                        >
                                          <Form.Label className="label fw-bold">
                                            Server Type
                                          </Form.Label>
                                          <Form.Select
                                            required
                                            aria-label="language example "
                                            className=""
                                            name="sectiontype"
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          >
                                            <option value="" disabled selected>
                                              Select Plugin Type
                                            </option>

                                            {dynamicdropdown?.map((data) => (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                              >
                                                {data.name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </Form.Group>
                                      )}

                                      {/* Website type (wordpress) */}
                                      {wordpressWebsiteType == true && (
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          lg="4"
                                          xl="3"
                                          className="mb-3"
                                        >
                                          <Form.Label className="label fw-bold">
                                            Website Type
                                          </Form.Label>

                                          <Form.Select
                                            aria-label="language example "
                                            className=""
                                            name="sectiontype"
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          >
                                            <option value="" disabled selected>
                                              Select Website Type
                                            </option>

                                            {dynamicdropdown?.map((data) => (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                              >
                                                {data.name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </Form.Group>
                                      )}

                                      {/* Package Type (writing & translation) */}
                                      {packageType == true && (
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          lg="4"
                                          xl="3"
                                          className="mb-3"
                                        >
                                          <Form.Label className="label fw-bold">
                                            Package Type
                                          </Form.Label>

                                          <Form.Select
                                            required
                                            aria-label="language example "
                                            className=""
                                            name="sectiontype"
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          >
                                            <option value="" disabled selected>
                                              Select Package Type
                                            </option>

                                            {dynamicdropdown?.map((data) => (
                                              <option
                                                key={data.id}
                                                value={data.id}
                                              >
                                                {data.name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </Form.Group>
                                      )}

                                      {/* Total Page */}
                                      {noOfPageBox == true && (
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          lg="4"
                                          xl="3"
                                          className="mb-3"
                                        >
                                          {serviceName.current.value == 1 &&
                                          subServiceName.current.value == 2 &&
                                          childService.current.value == 4 ? (
                                            <Form.Label className="label fw-bold">
                                              Section Type
                                            </Form.Label>
                                          ) : serviceName.current.value == 1 &&
                                            subServiceName.current.value == 2 &&
                                            childService.current.value == 5 ? (
                                            <Form.Label className="label fw-bold">
                                              Store Type
                                            </Form.Label>
                                          ) : (
                                            <Form.Label className="label fw-bold">
                                              Total Pages
                                            </Form.Label>
                                          )}

                                          <Form.Control
                                            type="text"
                                            name="totalPages"
                                            value={input.totalPages}
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          />
                                        </Form.Group>
                                      )}

                                      {/*USD Price */}
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        lg="4"
                                        xl="3"
                                        className="mb-3"
                                      >
                                        <Form.Label className="label fw-bold">
                                          USD Price
                                        </Form.Label>
                                        {serviceName?.current?.value == 1 ? (
                                          <Form.Control
                                            required
                                            type="number"
                                            step="0.01"
                                            min={0}
                                            placeholder="USD Price"
                                            name="usdPrice"
                                            value={input.usdPrice}
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          />
                                        ) : (
                                          <Form.Control
                                            required
                                            type="text"
                                            placeholder="USD Price"
                                            name="usdPrice"
                                            value={input.usdPrice}
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          />
                                        )}
                                      </Form.Group>

                                      {/* BDT Price */}
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        lg="4"
                                        xl="3"
                                        className="mb-3"
                                      >
                                        <Form.Label className="label fw-bold">
                                          BDT Price
                                        </Form.Label>
                                        {serviceName?.current?.value == 1 ? (
                                          <Form.Control
                                            required
                                            type="number"
                                            step="0.01"
                                            min={0}
                                            placeholder="BDT Price"
                                            name="bdtPrice"
                                            value={input.bdtPrice}
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          />
                                          
                                        ) : (
                                          <Form.Control
                                            required
                                            type="text"
                                            placeholder="BDT Price"
                                            name="bdtPrice"
                                            value={input.bdtPrice}
                                            onChange={(event) =>
                                              handleFormChange(index, event)
                                            }
                                          />
                                        )}
                                      </Form.Group>

                                      {/* Project duration */}
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        lg="3"
                                        xl="2"
                                        controlId="validationCustom01"
                                        className="mb-3"
                                      >
                                        <Form.Label className="label fw-bold">
                                          Duration
                                        </Form.Label>
                                        <Form.Control
                                          required
                                          type="number"
                                          placeholder="Duration"
                                          name="duration"
                                          min="0"
                                          value={input.duration}
                                          onChange={(event) =>
                                            handleFormChange(index, event)
                                          }
                                        />
                                      </Form.Group>

                                      <div className="col-lg-1 col-xl-1 align-self-center mt-3">
                                        <div className="">
                                          <AiOutlinePlusCircle
                                            onClick={addFields}
                                            className="cursor"
                                            size="2rem"
                                          />
                                        </div>
                                      </div>
                                      <div className="d-inline">
                                        {inputFields.length > 1 && (
                                          <button
                                            onClick={() => removeFields(index)}
                                            className="remove_btn"
                                          >
                                            <AiOutlineClose size="1.5rem" />
                                          </button>
                                        )}
                                      </div>
                                    </Row>
                                  </div>
                                </>
                              );
                            })}

                            <div className="flex_center">
                              <Button
                                type="submit"
                                className="btn-submit mt-5 rounded-3 border-0"
                              >
                                <FiSave /> &nbsp; Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminServiceCostingAdd;
