import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import AdminPrivateRoute from "./Component/PrivateRoute/AdminPrivateRoute";
import TopCompany from "./Pages/Admin/TopCompany";
import QuickServices from "./Pages/Admin/QuickServices";
import TopServices from "./Pages/Admin/TopServices";
import AdminOurQualityWork from "./Pages/Admin/AdminOurQualityWork";
import LatestProjectCategory from "./Pages/Admin/LatestProjectCategory";
import LatestProjects from "./Pages/Admin/LatestProjects";
import Admin from "./Pages/Admin/Admin";
import AdminDashboard from "./Pages/Admin/Dashboard/AdminDashboard";
import AdminLogin from "./Pages/Admin/AdminLogin";
import ClientReviews from "./Pages/Admin/ClientReviews";
import AdminFeedback from "./Pages/Admin/AdminFeedback";
import Departments from "./Pages/Admin/Career/Departments";
import Locations from "./Pages/Admin/Career/Locations";
import JobApplication from "./Pages/Admin/Career/JobApplication";
import BlogCategory from "./Pages/Admin/Blog/BlogCategory";
import BlogPost from "./Pages/Admin/Blog/BlogPost";
import Settings from "./Pages/Admin/Settings";
import Visitors from "./Pages/Admin/Visitors";
import Chatbox from "./Component/Chatbox/Chatbox";
import "./App.css";
import "./Assets/css/style.css";
import UserInbox from "./Pages/Admin/Inbox/Inbox";
import AdminOurProjectWorks from "./Pages/Admin/AdminOurProjectWorks";
import AdminOurWorkProjectCategory from "./Pages/Admin/AdminOurWorkProjectCategory";
import AdminRoleManagement from "./Pages/Admin/AdminRoleManagement";
import ManageAdmin from "./Pages/Admin/ManageAdmin";
import AdminLearnngCenter from "./Pages/Admin/AdminLearningCenter";
import AdminSubscriber from "./Pages/Admin/AdminSubscriber";
import AdminOrder from "./Pages/Admin/AdminOrder.js/AdminOrder";
import AdminOrderView from "./Pages/Admin/AdminOrder.js/AdminOrderView";
import AdminOrderUserView from "./Pages/Admin/AdminOrder.js/AdminOrderUserView";
import Employee from "./Pages/Admin/Employee/Employee";
import AdminCaseStudy from "./Pages/Admin/Case Study/AdminCaseStudy";
import AdminCaseStudyAdd from "./Pages/Admin/Case Study/AdminCaseStudyAdd";
import AdminCaseStudyView from "./Pages/Admin/Case Study/AdminCaseStudyView";
import AdminCaseStudyEdit from "./Pages/Admin/Case Study/AdminCaseStudyEdit";
import TechUseCategory from "./Pages/Admin/Admin Tech Use/Tech Use Category/TechUseCategory";
import TechUseCategoryAdd from "./Pages/Admin/Admin Tech Use/Tech Use Category/TechUseCategoryAdd";
import TechUseCategoryEdit from "./Pages/Admin/Admin Tech Use/Tech Use Category/TechUseCategoryEdit";
import TechUseAll from "./Pages/Admin/Admin Tech Use/TechUse/TechUseAll";
import TechUseAdd from "./Pages/Admin/Admin Tech Use/TechUse/TechUseAdd";
import TechUseEdit from "./Pages/Admin/Admin Tech Use/TechUse/TechUseEdit";
import ServiceDesignTypeAdd from "./Pages/Admin/Service Module/Service Design Type/ServiceDesignTypeAdd";
import ServiceDesignTypeEdit from "./Pages/Admin/Service Module/Service Design Type/ServiceDesignTypeEdit";
import ServiceDesignType from "./Pages/Admin/Service Module/Service Design Type/ServiceDesignType";
import AdminFaq from "./Pages/Admin/FAQ/Faqs/AdminFaq";
import AdminFaqAdd from "./Pages/Admin/FAQ/Faqs/AdminFaqAdd";
import AdminFaqEdit from "./Pages/Admin/FAQ/Faqs/AdminFaqEdit";
import AdminFaqCategory from "./Pages/Admin/FAQ/FaqCategory/AdminFaqCategory";
import AdminFaqCategoryAdd from "./Pages/Admin/FAQ/FaqCategory/AdminFaqCategoryAdd";
import AdmnFaqCategoryEdit from "./Pages/Admin/FAQ/FaqCategory/AdmnFaqCategoryEdit";
import EmployeeAdd from "./Pages/Admin/Employee/EmployeeAdd";
import AdminServiceCostingAdd from "./Pages/Admin/Service Module/AdminServiceCosting/ServiceCostingAdd";
import AllServiceCosting from "./Pages/Admin/Service Module/AdminServiceCosting/AllServiceCosting";
import AdminTeam from "./Pages/Admin/Team/AdminTeam";
import AdminTeamAdd from "./Pages/Admin/Team/AdminTeamAdd";
import AdminTeamEdit from "./Pages/Admin/Team/AdminTeamEdit";
import MasonaryProjectsAdd from "./Pages/Admin/Admin Latest Projects/MasonaryProjectsAdd";
import MasonaryProjects from "./Pages/Admin/Admin Latest Projects/MasonaryProjects";
import MasonaryProjectsEdit from "./Pages/Admin/Admin Latest Projects/MasonaryProjectsEdit";
import EmployeeEdit from "./Pages/Admin/Employee/EmployeeEdit";
import AdminPress from "./Pages/Admin/Admin Press/AdminPress";
import AdminPressAdd from "./Pages/Admin/Admin Press/AdminPressAdd";
import AdminPressView from "./Pages/Admin/Admin Press/AdminPressView";
import AdminPressEdit from "./Pages/Admin/Admin Press/AdminPressEdit";
import AdminProfile from "./Pages/Admin/Admin Profile/AdminProfile";
import AdminProfileDashboard from "./Pages/Admin/Admin Profile/AdminProfileDashboard";
import AdminProfileEdit from "./Pages/Admin/Admin Profile/AdminProfileEdit";
import AdminProfileNotification from "./Pages/Admin/Admin Profile/AdminProfileNotification";
import DynamicDropdown from "./Pages/Admin/Service Module/Dynamic Dropdown/DynamicDropdown";
import DynamicDropdownAdd from "./Pages/Admin/Service Module/Dynamic Dropdown/DynamicDropdownAdd";
import ExploreOpeningsAdd from "./Pages/Admin/Career/Explore Openings/ExploreOpeningsAdd";
import ExploreOpeningsEdit from "./Pages/Admin/Career/Explore Openings/ExploreOpeningsEdit";
import ExploreOpeningsView from "./Pages/Admin/Career/Explore Openings/ExploreOpeningsView";
import ExploreOpeningsAll from "./Pages/Admin/Career/Explore Openings/ExploreOpeningsAll";
import AdminRolePermission from "./Pages/Admin/AdminRolePermission";
import AdminServiceAll from "./Pages/Admin/Service Module/Service/AdminServiceAll";
import AdminServiceAdd from "./Pages/Admin/Service Module/Service/AdminServiceAdd";
import AdminServiceEdit from "./Pages/Admin/Service Module/Service/AdminServiceEdit";
import AdminServiceView from "./Pages/Admin/Service Module/Service/AdminServiceView";
import RegisteredUserAdd from "./Pages/Admin/Registered User/RegisteredUserAdd";
import RegisteredUserAll from "./Pages/Admin/Registered User/RegisteredUserAll";
import RegisteredUserEdit from "./Pages/Admin/Registered User/RegisteredUserEdit";
import RegisteredUserView from "./Pages/Admin/Registered User/RegisteredUserView";
import AdminOrderQuoteAll from "./Pages/Admin/Order Quote/AdminOrderQuoteAll";
import AdminOrderQuoteView from "./Pages/Admin/Order Quote/AdminOrderQuoteView";
import AdminOrderAdd from "./Pages/Admin/AdminOrder.js/AdminOrderAdd";
import AdditionalServiceAdd from "./Pages/Admin/Service Module/Additional Services/AdditionalServiceAdd";
import AdminMainServices from "./Pages/Admin/Service/Main Service/AdminMainServices";
import AdminMainServiceAdd from "./Pages/Admin/Service/Main Service/AdminMainServiceAdd";
import AdminMainServiceEdit from "./Pages/Admin/Service/Main Service/AdminMainServiceEdit";
import AdminMainServiceView from "./Pages/Admin/Service/Main Service/AdminMainServiceView";
import AdminSubServices from "./Pages/Admin/Service/Sub Service/AdminSubServices";
import AdminSubServiceAdd from "./Pages/Admin/Service/Sub Service/AdminSubServiceAdd";
import AdminSubServiceEdit from "./Pages/Admin/Service/Sub Service/AdminSubServiceEdit";
import AdminChildServices from "./Pages/Admin/Service/Child Service/AdminChildServices";
import AdminChildServiceAdd from "./Pages/Admin/Service/Child Service/AdminChildServiceAdd";
import AdminChildServiceEdit from "./Pages/Admin/Service/Child Service/AdminChildServiceEdit";
import AdditionalServiceAll from "./Pages/Admin/Service Module/Additional Services/AdditionalServiceAll";
import AdminAppointments from "./Pages/Admin/Admin Appointments/AdminAppointments";
import AdminAppointmnetView from "./Pages/Admin/Admin Appointments/AdminAppointmnetView";
import { AnimatePresence } from "framer-motion";
import AdminTrodadian from "./Pages/Admin/Trodadian/AdminTrodadian";
import AdditionalAppAll from "./Pages/Admin/Service Module/Additional App-Plugin/AdditionalAppAll";
import AdditionalAppAdd from "./Pages/Admin/Service Module/Additional App-Plugin/AdditionalAppAdd";
import AdditionalAppEdit from "./Pages/Admin/Service Module/Additional App-Plugin/AdditionalAppEdit";
import DynamicModuleAll from "./Pages/Admin/Service Module/Dynamic Module/DynamicModuleAll";
import DynamicModuleAdd from "./Pages/Admin/Service Module/Dynamic Module/DynamicModuleAdd";
import DynamicModuleEdit from "./Pages/Admin/Service Module/Dynamic Module/DynamicModuleEdit";
import AdditionalServiceEdit from "./Pages/Admin/Service Module/Additional Services/AdditionalServiceEdit";
import DynamicDropdownEdit from "./Pages/Admin/Service Module/Dynamic Dropdown/DynamicDropdownEdit";
import WebsiteMaintenanceProposal from "./Pages/Admin/WebsiteMaintenaceProposal/WebsiteMaintenanceProposal";
import WebsiteBrandingProposal from "./Pages/Admin/Website Branding Proposal/WebsiteBrandingProposal";
import Calender from "./Pages/Admin/Calender/Calender";
import AdminWorkspaceSettings from "./Pages/Admin/Admin Profile/AdminWorkspaceSettings";
import CreateNewOrderTwo from "./Pages/Admin/AdminOrder.js/CreateNewOrderTwo";
import AsanaDashboard from "./Pages/Admin/AdminProjectManagement/AsanaDashboard/AsanaDashboard";
import AsanaProjects from "./Pages/Admin/AdminProjectManagement/AsanaProjects/AsanaProjects";
import AsanaDashboardHome from "./Pages/Admin/AdminProjectManagement/AsanaDashboardHome/AsanaDashboardHome";
import AsanaSingleUserInfo from "./Pages/Admin/AdminProjectManagement/AsanaSingleuserInfo/AsanaSingleUserInfo";
import AsanaTeam from "./Pages/Admin/AdminProjectManagement/AsanaTeam/AsanaTeam";
import AsanaTasks from "./Pages/Admin/AdminProjectManagement/AsanaTasks/AsanaTasks";
import { Contextwrapper } from "./Pages/Admin/Calender/CalenderContext";
import AdminInbox from "./Pages/Admin/Admin Inbox/AdminInbox";
import AdminInboxOrderConversation from "./Pages/Admin/Admin Inbox/Order Conversation/AdminInboxConversation";
import ConversationSection from "./Pages/Admin/Admin Inbox/_partials/ConversationSection";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdminCaseStudyCategory from "./Pages/Admin/Case Study/Category/AdminCaseStudyCategory";
import AdminCaseStudyCategoryAdd from "./Pages/Admin/Case Study/Category/AdminCaseStudyCategoryAdd";
import AdminCaseStudyCategoryEdit from "./Pages/Admin/Case Study/Category/AdminCaseStudyCategoryEdit";
import "./ck.css";

export const UserContext = createContext();

function App() {
  const [modalShow, setModalShow] = useState(true);
  const [toggleNav, setToggleNav] = useState(false);
  const [currUserInfo, setCurrUserInfo] = useState();
  const [adminUserInfo, setAdminUserInfo] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [toggleBtn, setToggleBtn] = useState(
    window.innerWidth < 576 ? true : false
  );
  const [parentReload, setParentReload] = useState(false);
  return (
    <>
      {/* {(maintenanceMode == "0" || (maintenanceMode == "1" && maintenancePermission == true)) && ( */}
      <UserContext.Provider
        value={{
          currUserInfo,
          setCurrUserInfo,
          modalShow,
          setModalShow,
          toggleNav,
          setToggleNav,
          adminUserInfo,
          setAdminUserInfo,
          allRoles,
          setAllRoles,
          toggleBtn,
          setToggleBtn,
        }}
      >
        <Contextwrapper>
          <BrowserRouter>
            {/* <ScrollToTop /> */}
            <Chatbox />

            <AnimatePresence>
              <Routes>
                {/* =========================== admin dashboard =======================*/}

                <Route path="/" element={<AdminLogin />} />
                <Route
                  path="admin"
                  element={
                    <AdminPrivateRoute>
                      <Admin />
                    </AdminPrivateRoute>
                  }
                >
                  <Route index element={<AdminDashboard />} />
                  <Route path="trodadian-email" element={<AdminTrodadian />} />
                  <Route path="calender" element={<Calender />} />
                  <Route
                    path="role-management"
                    element={<AdminRoleManagement />}
                  />
                  <Route path="manage-admin" element={<ManageAdmin />} />
                  <Route
                    path="our-works/projects"
                    element={<AdminOurProjectWorks />}
                  />
                  <Route
                    path="our-works/project-category"
                    element={<AdminOurWorkProjectCategory />}
                  />

                  <Route path="inbox" element={<AdminInbox />}>
                    <Route
                      path="order-conversation"
                      element={
                        <AdminInboxOrderConversation
                          type="order"
                          setParentReload={setParentReload}
                        />
                      }
                    >
                      <Route
                        path=":slug"
                        element={
                          <ConversationSection
                            type="order"
                            parentReload={parentReload}
                            setParentReload={setParentReload}
                          />
                        }
                      />
                    </Route>
                    <Route
                      path="support-conversation"
                      element={
                        <AdminInboxOrderConversation
                          type="support"
                          setParentReload={setParentReload}
                        />
                      }
                    >
                      <Route
                        path=":slug"
                        element={
                          <ConversationSection
                            type="support"
                            parentReload={parentReload}
                            setParentReload={setParentReload}
                          />
                        }
                      />
                    </Route>
                  </Route>

                  <Route path="top-company" element={<TopCompany />} />
                  <Route path="quick-services" element={<QuickServices />} />
                  <Route path="top-services" element={<TopServices />} />

                  <Route
                    path="quality-works"
                    element={<AdminOurQualityWork />}
                  />
                  <Route
                    path="learning-center"
                    element={<AdminLearnngCenter />}
                  />
                  <Route
                    path="latest-projects/category"
                    element={<LatestProjectCategory />}
                  />
                  <Route path="latest-project" element={<LatestProjects />} />

                  <Route path="client-review" element={<ClientReviews />} />
                  <Route path="feedback" element={<AdminFeedback />} />

                  <Route path="career/departments" element={<Departments />} />
                  <Route path="career/locations" element={<Locations />} />
                  <Route
                    path="career/application"
                    element={<JobApplication />}
                  />

                  <Route path="blogs/category" element={<BlogCategory />} />
                  <Route path="blogs/post" element={<BlogPost />} />

                  <Route path="settings" element={<Settings />} />
                  <Route path="visitors" element={<Visitors />} />

                  <Route
                    path="role-management/role-permission/:roleId"
                    element={<AdminRolePermission />}
                  />

                  {/* ====================== Without modal ===================== */}

                  {/* Explore Openings */}
                  <Route
                    path="career/explore-openings"
                    element={<ExploreOpeningsAll />}
                  />
                  <Route
                    path="career/explore-openings/add-new"
                    element={<ExploreOpeningsAdd />}
                  />
                  <Route
                    path="career/explore-openings/edit/:jobopeningId"
                    element={<ExploreOpeningsEdit />}
                  />
                  <Route
                    path="career/explore-openings/view/:jobopeningId"
                    element={<ExploreOpeningsView />}
                  />

                  {/* Team */}
                  <Route path="team" element={<AdminTeam />} />
                  <Route path="team/add-new" element={<AdminTeamAdd />} />
                  <Route path="team/edit/:teamId" element={<AdminTeamEdit />} />

                  {/* Masonary Latest Projects */}
                  <Route
                    path="masonary-latest-projects"
                    element={<MasonaryProjects />}
                  />
                  <Route
                    path="masonary-latest-projects/add-new"
                    element={<MasonaryProjectsAdd />}
                  />
                  <Route
                    path="masonary-latest-projects/edit/:projectId"
                    element={<MasonaryProjectsEdit />}
                  />

                  {/* Final Service Module -> Main Service */}
                  <Route
                    path="final-service-module/main-services"
                    element={<AdminMainServices />}
                  />
                  <Route
                    path="final-service-module/main-services/add-new"
                    element={<AdminMainServiceAdd />}
                  />
                  <Route
                    path="final-service-module/main-services/edit/:serviceId"
                    element={<AdminMainServiceEdit />}
                  />
                  <Route
                    path="final-service-module/main-services/view/:serviceId"
                    element={<AdminMainServiceView />}
                  />

                  {/* Final Service Module -> Sub Service */}
                  <Route
                    path="final-service-module/sub-services"
                    element={<AdminSubServices />}
                  />
                  <Route
                    path="final-service-module/sub-services/add-new"
                    element={<AdminSubServiceAdd />}
                  />
                  <Route
                    path="final-service-module/sub-services/edit/:subServiceId"
                    element={<AdminSubServiceEdit />}
                  />

                  {/* Final Service Module -> Child Serivce */}
                  <Route
                    path="final-service-module/child-services"
                    element={<AdminChildServices />}
                  />
                  <Route
                    path="final-service-module/child-services/add-new"
                    element={<AdminChildServiceAdd />}
                  />
                  <Route
                    path="final-service-module/child-services/edit/:childServiceId"
                    element={<AdminChildServiceEdit />}
                  />

                  {/* Service Module -> Service */}
                  <Route
                    path="service-module/service"
                    element={<AdminServiceAll />}
                  />
                  <Route
                    path="service-module/service/add-new"
                    element={<AdminServiceAdd />}
                  />
                  <Route
                    path="service-module/service/edit/:serviceId"
                    element={<AdminServiceEdit />}
                  />
                  <Route
                    path="service-module/service/view/:serviceId"
                    element={<AdminServiceView />}
                  />

                  {/* Service Module -> Service Cost */}

                  <Route
                    path="service-module/additional-services/add-new"
                    element={<AdditionalServiceAdd />}
                  />
                  <Route
                    path="service-module/additional-services"
                    element={<AdditionalServiceAll />}
                  />
                  <Route
                    path="service-module/additional-services/edit/:serviceId"
                    element={<AdditionalServiceEdit />}
                  />
                  <Route
                    path="service-module/additional-app-plugin/add-new"
                    element={<AdditionalAppAdd />}
                  />
                  <Route
                    path="service-module/additional-app-plugin"
                    element={<AdditionalAppAll />}
                  />
                  <Route
                    path="service-module/additional-app-plugin/edit/:pluginId"
                    element={<AdditionalAppEdit />}
                  />
                  <Route
                    path="service-module/dynamic-module/add-new"
                    element={<DynamicModuleAdd />}
                  />
                  <Route
                    path="service-module/dynamic-module"
                    element={<DynamicModuleAll />}
                  />
                  <Route
                    path="service-module/dynamic-module/edit/:moduleId"
                    element={<DynamicModuleEdit />}
                  />

                  <Route
                    path="service-costing"
                    element={<AllServiceCosting />}
                  />
                  <Route
                    path="service-costing/add-new"
                    element={<AdminServiceCostingAdd />}
                  />

                  {/* Service Module -> Design Type */}
                  <Route
                    path="service-module/design-type"
                    element={<ServiceDesignType />}
                  />
                  <Route
                    path="service-module/design-type/add-new"
                    element={<ServiceDesignTypeAdd />}
                  />
                  <Route
                    path="service-module/design-type/edit/:designTypeId"
                    element={<ServiceDesignTypeEdit />}
                  />

                  {/* Service Module -> Dynamic Dropdown */}
                  <Route
                    path="service-module/dynamic-dropdown"
                    element={<DynamicDropdown />}
                  />
                  <Route
                    path="service-module/dynamic-dropdown/add-new"
                    element={<DynamicDropdownAdd />}
                  />
                  <Route
                    path="service-module/dynamic-dropdown/edit/:dropdownId"
                    element={<DynamicDropdownEdit />}
                  />

                  {/* Press */}
                  <Route path="press" element={<AdminPress />} />
                  <Route path="press/add-new" element={<AdminPressAdd />} />
                  <Route
                    path="press/view/:newsId"
                    element={<AdminPressView />}
                  />
                  <Route
                    path="press/edit/:newsId"
                    element={<AdminPressEdit />}
                  />

                  {/* Website maintenance */}
                  <Route
                    path="website-maintenance"
                    element={<WebsiteMaintenanceProposal />}
                  />
                  {/* Website branding */}
                  <Route
                    path="website-branding"
                    element={<WebsiteBrandingProposal />}
                  />

                  {/* Tech Use Category */}
                  <Route
                    path="tech-use-category"
                    element={<TechUseCategory />}
                  />
                  <Route
                    path="tech-use-category/add-new"
                    element={<TechUseCategoryAdd />}
                  />
                  <Route
                    path="tech-use-category/edit/:categoryId"
                    element={<TechUseCategoryEdit />}
                  />

                  {/* Tech Use */}
                  <Route path="tech-use" element={<TechUseAll />} />
                  <Route path="tech-use/add-new" element={<TechUseAdd />} />
                  <Route
                    path="tech-use/edit/:techUseId"
                    element={<TechUseEdit />}
                  />

                  {/* FAQ Categories*/}
                  <Route path="faq-category" element={<AdminFaqCategory />} />
                  <Route
                    path="faq-category/add-new"
                    element={<AdminFaqCategoryAdd />}
                  />
                  <Route
                    path="faq-category/edit/:categoryId"
                    element={<AdmnFaqCategoryEdit />}
                  />

                  {/* FAQ */}
                  <Route path="faq" element={<AdminFaq />} />
                  <Route path="faq/add-new" element={<AdminFaqAdd />} />
                  <Route path="faq/edit/:faqId" element={<AdminFaqEdit />} />

                  <Route path="subscriber" element={<AdminSubscriber />} />

                  {/* Order */}
                  <Route path="orders" element={<AdminOrder />} />
                  <Route path="orders/add-new" element={<AdminOrderAdd />} />
                  <Route
                    path="orders/view/:orderId"
                    element={<AdminOrderView />}
                  />
                  {/* <Route
                    path="orders/create-new-order"
                    element={<CreateNewOrder />}
                  /> */}
                  <Route
                    path="orders/create-new-order"
                    element={<CreateNewOrderTwo />}
                  />

                  {/* <Route
                    path="project-management"
                    element={<AdminProjectManagement />}
                  /> */}

                  <Route
                    path="case-study/categories"
                    element={<AdminCaseStudyCategory />}
                  />
                  <Route
                    path="case-study/categories/add-new"
                    element={<AdminCaseStudyCategoryAdd />}
                  />
                  <Route
                    path="case-study/category/edit/:categoryId"
                    element={<AdminCaseStudyCategoryEdit />}
                  />

                  {/* Case Study */}
                  <Route path="case-study/posts" element={<AdminCaseStudy />} />
                  <Route
                    path="case-study/posts/add-new"
                    element={<AdminCaseStudyAdd />}
                  />
                  <Route
                    path="case-study/posts/view/:caseStudyId"
                    element={<AdminCaseStudyView />}
                  />
                  <Route
                    path="case-study/posts/edit/:caseStudyId"
                    element={<AdminCaseStudyEdit />}
                  />
                  {/* ! =================== Asana Dashboard  =======================*/}
                  <Route path="asana-dashboard" element={<AsanaDashboard />}>
                    <Route index element={<AsanaDashboardHome />} />
                    <Route path="my-tasks" element={<AsanaTasks />} />
                    <Route
                      path="projects/:projectId"
                      element={<AsanaProjects />}
                    />
                    <Route
                      path="users/:userId"
                      element={<AsanaSingleUserInfo />}
                    />
                    <Route path="team/:teamGid" element={<AsanaTeam />} />
                  </Route>

                  <Route
                    path="orders/user/:orderId/:userId"
                    element={<AdminOrderUserView />}
                  />
                  <Route path="appointments" element={<AdminAppointments />} />
                  <Route
                    path="appointments/view/:appointmentId"
                    element={<AdminAppointmnetView />}
                  />

                  {/* Get Quote */}
                  <Route path="order-quote" element={<AdminOrderQuoteAll />} />
                  <Route
                    path="order-quote/view/:quoteId"
                    element={<AdminOrderQuoteView />}
                  />
                  <Route path="employee/add-new" element={<EmployeeAdd />} />
                  <Route
                    path="employee/edit/:employeeId"
                    element={<EmployeeEdit />}
                  />
                  <Route path="employee" element={<Employee />} />

                  {/* Registeded User */}
                  <Route
                    path="registered-user"
                    element={<RegisteredUserAll />}
                  />
                  <Route
                    path="registered-user/add-new"
                    element={<RegisteredUserAdd />}
                  />
                  <Route
                    path="registered-user/view/:userId"
                    element={<RegisteredUserView />}
                  />
                  <Route
                    path="registered-user/edit/:userId"
                    element={<RegisteredUserEdit />}
                  />
                </Route>

                <Route path="/admin/profile" element={<AdminProfile />}>
                  <Route index element={<AdminProfileDashboard />} />
                  <Route path="edit-profile" element={<AdminProfileEdit />} />
                  <Route
                    path="notification-settings"
                    element={<AdminProfileNotification />}
                  />
                  <Route
                    path="workspace"
                    element={<AdminWorkspaceSettings />}
                  />
                </Route>
              </Routes>
            </AnimatePresence>
            {/* </Route> */}
          </BrowserRouter>
        </Contextwrapper>
      </UserContext.Provider>
      {/* )} */}
    </>
  );
}

export default App;
