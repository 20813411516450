import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AdminDashboardNavbar from "../../../../Component/AdminDashboardNavbar";
import getCookie from "../../../../Component/GetCookie";

import { BACKEND_BASE_URL } from "../../../../Component/GlobalVariables";
import { getLocalStorageWithExpiry } from "../../../../Component/LocalStorageWithExpiry";

const AdminCaseStudyCategoryAdd = () => {
  const CategoryName = useRef();

  // form submit to backend
  const storeData = (e) => {
    const formdata = new FormData();
    formdata.append("name", CategoryName.current.value);

    axios
      .post(
        `${BACKEND_BASE_URL}/api/v2/rianaire/case-study/category/store`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getLocalStorageWithExpiry(
              "ACCESS_TOKEN"
            )}`,
          },
        }
      )
      .then((response) => {
        if (response.data?.status === 200) {
          Swal.fire({
            icon: "success",
            text: response.data?.message,
            confirmButtonColor: "#5eba86",
          });
          e.target.reset();
        }
      });
    e.preventDefault();
  };
  return (
    <div className="main__container">
      <div className="body-wrapper">
        <AdminDashboardNavbar />

        <div className="col-md-12 p-4">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12">
                <div className="card-header flex_end mb-5">
                  <Link to="/admin/faq-category">
                    <Button variant="success" size="sm">
                      All Faqs&nbsp;
                    </Button>
                  </Link>
                </div>
                <Form onSubmit={storeData}>
                  <div className="content-wrapper">
                    <div className="card">
                      <div className="card-body">
                        <div className="row py-3">
                          {/* <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Label className="label fw-bold">
                          Design Type
                        </Form.Label>
                        <Form.Select
                          aria-label="language example "
                          className=""
                          ref={designType}
                        >
                          <option value="" disabled selected>
                            Select Design Type
                          </option>
                          {designtype?.map((designType) => (
                            <option
                              key={designType.id}
                              value={designType.id}
                            >
                               {designType?.sub_service?.name}{ " -> " }{designType.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group> */}

                          {/* add News title */}
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="mb-3"
                          >
                            <Form.Label className="label fw-bold">
                              Category Name
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              placeholder="Category Name"
                              ref={CategoryName}
                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className="btn-submit mt-5 rounded-3 border-0"
                          >
                            <FiSave /> &nbsp; Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCaseStudyCategoryAdd;
